import get from 'lodash/get';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import Immutable from 'immutable';
import { isSharedUrl } from 'utils/utils';
import * as sharedUtil from 'utils/utils';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants.js';
import {
  /* fetch details */
  FETCH_ADJUSTER_DETAILS,
  FETCH_ADJUSTER_DETAILS_FAILURE,
  FETCH_ADJUSTER_DETAILS_SUCCESS,
  /* fetch gallery */
  FETCH_ADJUSTER_DETAILS_GALLERY,
  FETCH_ADJUSTER_DETAILS_GALLERY_FAILURE,
  FETCH_ADJUSTER_DETAILS_GALLERY_SUCCESS,
  /* fetch gallery image metadata */
  FETCH_GALLERY_IMAGE_METADATA,
  FETCH_GALLERY_IMAGE_METADATA_FAILURE,
  FETCH_GALLERY_IMAGE_METADATA_SUCCESS,
  /* fetch tags */
  FETCH_ADJUSTER_DETAILS_TAGS,
  FETCH_ADJUSTER_DETAILS_TAGS_SUCCESS,
  FETCH_ADJUSTER_DETAILS_TAGS_FAILURE,
  /* fetch notes */
  FETCH_ADJUSTER_DETAILS_NOTES,
  FETCH_ADJUSTER_DETAILS_NOTES_SUCCESS,
  FETCH_ADJUSTER_DETAILS_NOTES_FAILURE,
  /* save tags */
  SAVE_ADJUSTER_DETAILS_TAGS,
  SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  SAVE_ADJUSTER_DETAILS_TAGS_FAILURE,
  /* save rotation data for images */
  SAVE_ROTATION_FOR_GALLERY_IMAGES,
  SAVE_ROTATION_FOR_GALLERY_IMAGES_SUCCESS,
  SAVE_ROTATION_FOR_GALLERY_IMAGES_FAILURE,
  /* delete tags */
  DELETE_ADJUSTER_DETAILS_TAGS,
  DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  DELETE_ADJUSTER_DETAILS_TAGS_FAILURE,
  /* save notes */
  SAVE_ADJUSTER_DETAILS_NOTES,
  SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  SAVE_ADJUSTER_DETAILS_NOTES_FAILURE,
  /* delete notes */
  DELETE_ADJUSTER_DETAILS_NOTES,
  DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  DELETE_ADJUSTER_DETAILS_NOTES_FAILURE,
  /* report generator count */
  INCREMENT_GENERATE_REPORT_DEPENDENTS,
  DECREMENT_GENERATE_REPORT_DEPENDENTS,
  /* facet review decision */
  SAVE_FACET_REVIEW_DECISION,
  SAVE_FACET_REVIEW_DECISION_SUCCESS,
  SAVE_FACET_REVIEW_DECISION_FAILURE,
  /* fetch iw image metadata */
  FETCH_IW_IMAGE_METADATA,
  FETCH_IW_IMAGE_METADATA_SUCCESS,
  FETCH_IW_IMAGE_METADATA_FAILURE,
  FETCH_FACET_IMAGES,
  FETCH_FACET_IMAGES_SUCCESS,
  FETCH_FACET_IMAGES_FAILURE,
  /* save confidence factor */
  SAVE_CONFIDENCE_FACTOR_SUCCESS,
  SAVE_CONFIDENCE_FACTOR_FAILURE,
  /* workflow panel */
  SET_WORKFLOW_ANOMALY_ID,
  SET_ANOMALY_DECISION,
  SAVE_ANOMALY_DECISION_SUCCESS,
  SAVE_ANOMALY_DECISION_FAILURE,
  CLEAR_ERRORS,
  FETCH_ROOF_FACETS,
  FETCH_ROOF_FACETS_FAILURE,
  FETCH_ROOF_FACETS_SUCCESS,
  CLEAR_BASE_IMAGE_AND_FACETS,
  FETCH_ROOF_FACETS_AND_BASE_IMAGE,
  POST_REPORT,
  POST_REPORT_SUCCESS,
  POST_REPORT_FAILURE,
  POLL_REPORT,
  POLL_REPORT_SUCCESS,
  POLL_REPORT_FAILURE,
  SET_SELECTED_IMAGE,
  FETCH_FACET_BASE_IMAGE,
  FETCH_FACET_BASE_IMAGE_FAILURE,
  FETCH_FACET_BASE_IMAGE_SUCCESS,
  CLOSE_ERROR_TOAST,
  SET_SELECTED_FACET,
  SET_CURRENT_TAB,
  SET_CURRENT_NOTES_TAB,
  SET_ORDER_ID,
  SET_REPORT_ORDER_ID,
  FETCH_RISK_SHOT,
  FETCH_RISK_SHOT_SUCCESS,
  FETCH_RISK_SHOT_FAILURE,
  SET_CURRENT_VIEW,
  /* add user anomaly */
  ADD_USER_ANOMALY,
  ADD_USER_ANOMALY_SUCCESS,
  ADD_USER_ANOMALY_FAILURE,
  /* delete user anomaly */
  DELETE_USER_ANOMALY,
  DELETE_USER_ANOMALY_SUCCESS,
  DELETE_USER_ANOMALY_FAILURE,
  /* save roof decision */
  SAVE_ROOF_DECISION,
  SAVE_ROOF_DECISION_SUCCESS,
  SAVE_ROOF_DECISION_FAILURE,
  SET_ROOF_DECISION,
  ROOF_DECISION,
  FETCH_ANOMALY_METADATA_SUCCESS,
  FETCH_ANOMALY_METADATA_FAILURE,
  FETCH_ANOMALY_METADATA,
  SAVE_FACET_REPORT_INCLUSION,
  SAVE_FACET_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_REPORT_INCLUSION_FAILURE,
  SET_FACET_REPORT_INCLUSION,
  FETCH_REPORT_INCLUDED_IMAGES,
  FETCH_REPORT_INCLUDED_IMAGES_SUCCESS,
  FETCH_REPORT_INCLUDED_IMAGES_FAILURE,
  SAVE_IMAGE_REPORT_INCLUSION,
  SAVE_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_IMAGE_REPORT_INCLUSION,
  DELETE_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_IMAGE_REPORT_INCLUSION_FAILURE,
  RESET_ADJUSTER,
  SET_USER,
  SAVE_FACET_NOTES_SUCCESS,
  SAVE_FACET_NOTES_FAILURE,
  SAVE_FACET_NOTES,
  DELETE_FACET_NOTES_SUCCESS,
  DELETE_FACET_NOTES_FAILURE,
  DELETE_FACET_NOTES,
  UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE,
  UPDATE_ADJUSTER_DETAILS_NOTES,
  UPDATE_FACET_NOTES_SUCCESS,
  UPDATE_FACET_NOTES_FAILURE,
  UPDATE_FACET_NOTES,
  FETCH_FACET_NOTES,
  FETCH_FACET_NOTES_SUCCESS,
  FETCH_FACET_NOTES_FAILURE,
  FETCH_FACET_TEST_SQUARE_NOTES,
  FETCH_FACET_TEST_SQUARE_NOTES_SUCCESS,
  FETCH_FACET_TEST_SQUARE_NOTES_FAILURE,
  FETCH_ROOF_NOTE,
  FETCH_ROOF_NOTE_SUCCESS,
  FETCH_ROOF_NOTE_FAILURE,
  SAVE_ROOF_NOTE_FAILURE,
  SAVE_ROOF_NOTE_SUCCESS,
  SAVE_ROOF_NOTE,
  UPDATE_ROOF_NOTE_SUCCESS,
  UPDATE_ROOF_NOTE_FAILURE,
  UPDATE_ROOF_NOTE,
  SET_SELECTED_ANOMALY_VIEW,
  DELETE_ROOF_NOTE_SUCCESS,
  DELETE_ROOF_NOTE_FAILURE,
  DELETE_ROOF_NOTE,
  SHOW_ERROR_TOAST,
  SHOW_REPORT_FAILURE,
  WORKFLOW_PANEL,
  SET_SIDE_PANEL_WIDTH,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION_FAILURE,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_NOTE_REPORT_INCLUSION,
  SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE,
  FETCH_SOFT_METAL_IMAGES,
  FETCH_SOFT_METAL_IMAGES_SUCCESS,
  FETCH_SOFT_METAL_IMAGES_FAILURE,
  COMPLETE_REVIEW_FAILURE,
  FETCH_QC_PANEL_DETAILS,
  FETCH_QC_PANEL_DETAILS_SUCCESS,
  QC_APPROVE_FAILURE,
  QC_REJECT_FAILURE,
  QC_RETIEPOINT_FAILURE,
  QC_RESCHEDULE_FAILURE,
  QC_APPROVED_SUCCESS,
  QC_REJECT_SUCCESS,
  QC_RETIEPOINT_SUCCESS,
  QC_OPS_CHECKOUT_BY_REPORT_ID_SUCCESS,
  QC_OPS_CHECKOUT_BY_REPORT_ID_FAILURE,
  QC_OPS_CHECKIN_SIMPLE_FAILURE,
  QC_OPS_REJECT_SIMPLE_NOTES_FAILURE,
  QC_OPS_ASSIGN_SELF,
  QC_RESCHEDULE_SUCCESS,
  GET_QC_DETAILS_FAILED,
  HANDLE_QC_NOTE_CHANGE,
  HANDLE_QC_REASON_CHANGE,
  HANDLE_QC_REASONS_CHANGE,
  HANDLE_QC_REASONS_EMPTY_REQUEST,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE,
  SET_SHOW_SKYDIO_MOSAIC_IMAGE_PANEL,
  REJECT_QC_STRUCTURE_SAGA_SUCCESS_ACTION,
  REJECT_QC_STRUCTURE_SAGA_FAILURE_ACTION,
  UNDO_QC_STRUCTURE_REJECTION_SAGA_SUCCESS_ACTION,
  UNDO_QC_STRUCTURE_REJECTION_SAGA_FAILURE_ACTION,
  CLEAR_SKYDIO_MOSAIC_IMAGE_URL,
  /* display gallery annotations */
  DISPLAY_GALLERY_ANNOTATIONS,
  DISPLAY_GALLERY_ANNOTATIONS_SUCCESS,
  DISPLAY_GALLERY_ANNOTATIONS_FAILURE,
  /* save gallery annotation */
  SAVE_GALLERY_ANNOTATIONS,
  SAVE_GALLERY_ANNOTATIONS_SUCCESS,
  SAVE_GALLERY_ANNOTATIONS_FAILURE,
  /* update gallery annotation */
  UPDATE_GALLERY_ANNOTATIONS,
  UPDATE_GALLERY_ANNOTATIONS_SUCCESS,
  UPDATE_GALLERY_ANNOTATIONS_FAILURE,
  /* update facet annotation */
  UPDATE_FACET_ANNOTATION,
  UPDATE_FACET_ANNOTATION_SUCCESS,
  UPDATE_FACET_ANNOTATION_FAILURE,
  /* delete gallery annotation */
  DELETE_GALLERY_ANNOTATIONS,
  DELETE_GALLERY_ANNOTATIONS_SUCCESS,
  DELETE_GALLERY_ANNOTATIONS_FAILURE,
  /* set applied filters for gallery images */
  SET_APPLIED_GALLERY_IMAGE_FILTERS,
  /* test square status */
  TEST_SQUARE_STATUS_UPDATE,
  TEST_SQUARE_STATUS_UPDATE_SUCCESS,
  TEST_SQUARE_STATUS_UPDATE_FAILURE,
  /* test square location update */
  TEST_SQUARE_LOCATION_UPDATE,
  TEST_SQUARE_LOCATION_UPDATE_SUCCESS,
  TEST_SQUARE_LOCATION_UPDATE_FAILURE,
  /* App Settings */
  FETCH_ASSESS_APP_SETTINGS,
  FETCH_ASSESS_APP_SETTINGS_SUCCESS,
  FETCH_ASSESS_APP_SETTINGS_FAILURE,
  /* Anomaly include in report */
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT,
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT_SUCCESS,
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT_FAILURE,
  /* export images */
  EXPORT_ALL_IMAGES,
  EXPORT_ALL_IMAGES_FAILURE,
  EXPORT_REPORT_INCLUDED_IMAGES,
  EXPORT_REPORT_INCLUDED_IMAGES_FAILURE,
  POLL_EXPORT_IMAGES,
  POLL_EXPORT_IMAGES_SUCCESS,
  POLL_EXPORT_IMAGES_FAILURE,
  DOWNLOAD_EXPORTED_IMAGES,
  DOWNLOAD_EXPORTED_IMAGES_SUCCESS,
  DOWNLOAD_EXPORTED_IMAGES_FAILURE,
  EXPORT_IMAGERY,
  /* fetch report versions */
  FETCH_REPORT_VERSIONS_SUCCESS,
  FETCH_REPORT_VERSIONS_FAILURE,
  SET_FACET_NOTE_REPORT_INCLUSION,
  SET_GALLERY_IMAGE_NOTE_REPORT_INCLUSION,
  SET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
  /* fetch feature preferences */
  FETCH_FEATURE_PREFERENCE,
  FETCH_FEATURE_PREFERENCE_SUCCESS,
  FETCH_FEATURE_PREFERENCE_FAILURE,
  /* save facet annotations */
  SAVE_FACET_ANNOTATION,
  SAVE_FACET_ANNOTATION_SUCCESS,
  SAVE_FACET_ANNOTATION_FAILURE,
  /* display facet annotations */
  DISPLAY_FACET_ANNOTATIONS,
  DISPLAY_FACET_ANNOTATIONS_SUCCESS,
  DISPLAY_FACET_ANNOTATIONS_FAILURE,
  SET_FACET_BOX_ANNOTATIONS,
  /* delete gallery annotation */
  DELETE_FACET_ANNOTATION,
  DELETE_FACET_ANNOTATION_SUCCESS,
  DELETE_FACET_ANNOTATION_FAILURE,
  /* update user defined anomaly location */
  UPDATE_USER_DEFINED_ANOMALY_LOCATION,
  UPDATE_USER_DEFINED_ANOMALY_LOCATION_SUCCESS,
  UPDATE_USER_DEFINED_ANOMALY_LOCATION_FAILURE,
  /* fetch soft metal image metadata */
  FETCH_FACET_IMAGE_METADATA,
  FETCH_SOFT_METAL_IMAGE_METADATA_SUCCESS,
  FETCH_ANOMALY_IMAGE_METADATA_SUCCESS,
  FETCH_FACET_IMAGE_METADATA_FAILURE,
  /* workflow map navigation */
  ENABLE_WORKFLOW_NAVIGATION,
  DISABLE_WORKFLOW_NAVIGATION,
  SHOW_WORKFLOW_FACET_POLYGON,
  HIDE_WORKFLOW_FACET_POLYGON,
  REPLACE_FACET_METADATA,
  SET_WORKFLOW_BASEIMAGE_PARENT,
  SET_WORKFLOW_VIEW_BASE_IMG,
  /* fetch master accessory list */
  FETCH_ACCESSORIES_MASTER_LIST_FAILURE,
  FETCH_ACCESSORIES_MASTER_LIST_SUCCESS,
  FETCH_ACCESSORIES_MASTER_LIST,
  /* fetch accessory summary */
  FETCH_ACCESSORIES_SUMMARY,
  FETCH_ACCESSORIES_SUMMARY_SUCCESS,
  FETCH_ACCESSORIES_SUMMARY_FAILURE,
  /* set multi structure */
  SET_SELECTED_STRUCTURE_ID,
  FETCH_MULTISTRUCTURE_DECISION_SUCCESS,
  UPDATE_STRUCTURE_VISIT_STATUS_SUCCESS,

  FETCH_ANOMALY_IMAGES,
  FETCH_ANOMALY_IMAGES_SUCCESS_ACTION,
  FETCH_ANOMALY_IMAGES_FAILURE_ACTION,
  TABS,

  FETCH_PRODUCT_COST,
  FETCH_PRODUCT_COST_SUCCESS,
  FETCH_PRODUCT_COST_FAILURE,
  CLEAR_REPORT_STATUS,
  GENERATE_ROOF_REPORT,
  GENERATE_ROOF_REPORT_SUCCESS,
  GENERATE_ROOF_REPORT_FAILURE,

  REGENERATE_REPORT,
  REGENERATE_REPORT_SUCCESS,
  REGENERATE_REPORT_FAILURE,

  SAVE_QC_NOTE,
  SAVE_QC_NOTE_SUCCESS,
  SAVE_QC_NOTE_FAILURE,

  CLEAR_ANNOTATION_TO_EDIT,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_SUCCESS,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_FAILURE,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_SUCCESS,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_FAILURE,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_SUCCESS,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_FAILURE,

  REPORT_STATUS_IN_PROCESS,
  REPORT_STATUS_PAYMENT_FAILED,

  REGENERATE_REPORT_STATUS_INPROCESS,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_SUCCESS,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_FAILURE,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE,
  GET_ANOMALY_NOTES,
  GET_ANOMALY_NOTES_SUCCESS,
  GET_ANOMALY_NOTES_FAILURE,
  ADD_ANOMALY_NOTE,
  ADD_ANOMALY_NOTE_SUCCESS,
  ADD_ANOMALY_NOTE_FAILURE,
  UPDATE_ANOMALY_NOTE,
  UPDATE_ANOMALY_NOTE_SUCCESS,
  UPDATE_ANOMALY_NOTE_FAILURE,
  UPDATE_DATE_OF_LOSS,
  UPDATE_DATE_OF_LOSS_SUCCESS,
  UPDATE_DATE_OF_LOSS_FAILURE,
  INCLUDE_ANOMALY_NOTE,
  INCLUDE_ANOMALY_NOTE_SUCCESS,
  INCLUDE_ANOMALY_NOTE_FAILURE,
  SET_FACET_SCAN_IMAGE_ASSETS,
  FETCH_FACET_IMAGE_ANOMALIES,
  FETCH_FACET_IMAGE_ANOMALIES_SUCCESS,
  FETCH_FACET_IMAGE_ANOMALIES_FAILURE,
  SET_RAW_IMAGE_SELECTED_ANOMALY_ID,
  SKYDIO_IMAGE_DOWNLOAD_ERROR,
  FETCH_SHARED_USERS_LIST,
  FETCH_SHARED_USERS_LIST_SUCCESS,
  FETCH_SHARED_USERS_LIST_FAILURE,
  CREATE_SHARE_LINK,
  CREATE_SHARE_LINK_SUCCESS,
  CREATE_SHARE_LINK_FAILURE,
  DISABLE_GENERATE_REPORT,
  REPORT_ATTEMPTS,
  REPORT_ATTEMPTS_SUCCESS,
  REPORT_ATTEMPTS_FAILURE,
  FETCH_ACCESS_TOKEN,
  UPDATE_ACCESS_TOKEN,
  UPDATE_ACCESS_TOKEN_COMPLETED,
} from './Adjuster.constants';

import * as util from './Adjuster.utils';

export const initialState = {
  claimId: '',
  riskShot: {},
  addressStreet: '',
  addressCity: '',
  policyInsurancePerilCode: '',
  policyDateOfLoss: undefined,
  selectedImage: undefined,
  currentView: {},
  tags: [],
  notes: [],
  facetNotes: [],
  facetTestSquareNotes: [],
  galleryImages: [],
  currentTabGalleryImages: [],
  filteredGalleryImages: [],
  appliedGalleryImageFilters: {},
  softMetalImages: [],
  facets: [],
  labelAssets: [],
  selectedFacet: '',
  selectedAnomalyId: '',
  selectedImageOrientation: undefined,
  currentTab: TABS.DETAILS,
  currentNotesTab: 0,
  loading: {},
  reportGeneratorCount: 0,
  reports: {},
  error: {},
  showErrorToast: false,
  errorToastMessage: '',
  errorCode: 0,
  anomalies: [],
  orderId: '',
  orderStatus: '',
  assessStatus: '',
  orderCompleted: false,
  falconTags: [],
  customTags: [],
  reportOrderId: '',
  metadata: undefined,
  facetScanMetadata: undefined,
  facetScanAssets: [],
  facetImages: [],
  roofDecision: {},
  structureRoofMapping: {},
  featureFlags: [],
  selectedAnomalyMetadata: undefined,
  reportIncludedImages: [],
  user: {},
  roofNote: {},
  sidePanelWidth: '370px',
  chartData: '',
  coveragePercentage: 0,
  qcPanelType: '',
  qcEndTime: '',
  qcStartTime: '',
  QcClaimID: '',
  QcNotesList: Immutable.Map({}),
  qcOpsPrimeReport: {},
  hideQcPanel: false,
  qcNotes: Immutable.Map({}),
  qcReason: '',
  qcReasons: [],
  isQcD2m: false,
  assignQcOpsTiepointingToSelf: false,
  showSkydioMosaicImagePanel: false,
  severity: 'error',
  gallaryAnnotations: [],
  facetBoxAnnotations: [],
  deleteStatus: false,
  toastAutoHideDuration: 10000,
  testSquareData: {},
  facetAnomaliesSubset: [],
  testSquareError: null,
  facetTestSquareDisabledMap: {},
  selectedConfidenceFactor: undefined,
  assessAppSettings: {},
  reportList: [],
  lastSelectedImage: undefined,
  testSquareLocationApiFail: undefined,
  updateAnomalyLocationApiFail: undefined,
  featurePreferences: undefined,
  workflowPanelNavigationControl: {
    disablePitchAndRotation: true,
    disableZoom: true,
    disablePan: true,
  },
  workflowPanelDisplayFacetPolygon: false,
  workflowPanelSetBaseImgParent: false,
  accessoriesMasterList: [],
  accessoriesSummary: null,
  skydioMosaicImageUrl: '',
  skydioMosaicPresent: false,
  multiStructure: {},
  selectedStructureId: '',
  rejectedQcStructures: Immutable.Map({}),
  anomalyImages: [],
  isAssessLite: null,
  isOrderPlacedWithMeasurementFeeProduct: null,
  flightType: '',
  dateOrderCapture: undefined,
  dateCreated: undefined,
  createdAnnotationToEdit: {},
  reportIncludedSoftMetalImages: [],
  reportIncludedSkydioImages: [],
  anomalyNotes: [],
  rawImageAnomalies: [],
  rawImageSelectedAnomalyId: '',
  sharedUsersList: [],
  disableGenerateReport: false,
  canGenerateReport: false,
  accessToken: '',
  isPhotosUnspported: false,
};

const adjusterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ADJUSTER_DETAILS:
    case FETCH_ADJUSTER_DETAILS_GALLERY:
    case SAVE_ROTATION_FOR_GALLERY_IMAGES:
    case FETCH_GALLERY_IMAGE_METADATA:
    case FETCH_FACET_IMAGE_METADATA:
    case FETCH_ADJUSTER_DETAILS_TAGS:
    case FETCH_ADJUSTER_DETAILS_NOTES:
    case SAVE_FACET_REVIEW_DECISION:
    case FETCH_IW_IMAGE_METADATA:
    case FETCH_RISK_SHOT:
    case SAVE_ROOF_DECISION:
    case FETCH_ANOMALY_METADATA:
    case SAVE_FACET_REPORT_INCLUSION:
    case FETCH_REPORT_INCLUDED_IMAGES:
    case SAVE_IMAGE_REPORT_INCLUSION:
    case DELETE_IMAGE_REPORT_INCLUSION:
    case SAVE_ADJUSTER_DETAILS_TAGS:
    case DELETE_ADJUSTER_DETAILS_TAGS:
    case POST_REPORT:
    case DELETE_ADJUSTER_DETAILS_NOTES:
    case FETCH_ROOF_FACETS:
    case FETCH_FACET_BASE_IMAGE:
    case SAVE_ADJUSTER_DETAILS_NOTES:
    case FETCH_FACET_NOTES:
    case FETCH_FACET_TEST_SQUARE_NOTES:
    case FETCH_ROOF_NOTE:
    case SAVE_ROOF_NOTE:
    case UPDATE_ROOF_NOTE:
    case DELETE_ROOF_NOTE:
    case DISPLAY_GALLERY_ANNOTATIONS:
    case SAVE_GALLERY_ANNOTATIONS:
    case DELETE_GALLERY_ANNOTATIONS:
    case ADD_USER_ANOMALY:
    case SAVE_FACET_NOTE_REPORT_INCLUSION:
    case SAVE_IMAGE_NOTE_REPORT_INCLUSION:
    case FETCH_QC_PANEL_DETAILS:
    case DOWNLOAD_SKYDIO_MOSAIC_IMAGE:
    case TEST_SQUARE_LOCATION_UPDATE:
    case FETCH_FEATURE_PREFERENCE:
    case FETCH_ASSESS_APP_SETTINGS:
    case FETCH_SOFT_METAL_IMAGES:
    case TOGGLE_ANOMALY_INCLUDE_IN_REPORT:
    case SAVE_FACET_ANNOTATION:
    case DISPLAY_FACET_ANNOTATIONS:
    case DELETE_FACET_ANNOTATION:
    case UPDATE_USER_DEFINED_ANOMALY_LOCATION:
    case SAVE_QC_NOTE:
    case FETCH_ANOMALY_IMAGES:
    case FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES:
    case SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION:
    case DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION:
    case FETCH_REPORT_INCLUDED_SKYDIO_IMAGES:
    case SAVE_SKYDIO_IMAGE_REPORT_INCLUSION:
    case DELETE_SKYDIO_IMAGE_REPORT_INCLUSION:
    case FETCH_PRODUCT_COST:
    case GENERATE_ROOF_REPORT:
    case REGENERATE_REPORT:
    case GET_ANOMALY_NOTES:
    case ADD_ANOMALY_NOTE:
    case UPDATE_ANOMALY_NOTE:
    case UPDATE_DATE_OF_LOSS:
    case INCLUDE_ANOMALY_NOTE:
    case FETCH_FACET_IMAGE_ANOMALIES:
    case FETCH_SHARED_USERS_LIST:
    case CREATE_SHARE_LINK:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_ROOF_FACETS_AND_BASE_IMAGE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ROOF_FACETS]: true, [FETCH_FACET_BASE_IMAGE]: true },
      };
    case FETCH_FACET_IMAGES:
      return {
        ...state,
        loading: {
          ...state.loading,
          facetImages: {
            ...(state.loading.facetImages || {}),
            ...Object.values(payload.facetIds).reduce((loading, facetId) => ({
              ...loading,
              [facetId]: true,
            }), {}),
          },
        },
      };
    /* fetch details */
    case FETCH_ADJUSTER_DETAILS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS]: false },
        errorToastMessage: 'adjuster.claimsDetailError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        hideQcPanel: true,
      };
    case FETCH_ADJUSTER_DETAILS_SUCCESS: {
      const isAssessLite = get(payload, 'IsAssessLite', false);
      const isOrderPlacedWithMeasurementFeeProduct = get(payload, 'IsOrderPlacedWithMeasurementFeeProduct', false);
      const confidenceFactor = get(payload, 'Info.Details.ConfidenceScoreSelection', 'Recommended');
      const isD2mOrder = get(payload, 'Info.Details.IsD2MOrder', 'false');
      const updatedAnomalies = util.getUpdatedAnomaliesFromFacets(state.facets, confidenceFactor, state.assessAppSettings);
      const testSquareAnomalies = get(state, 'testSquareData.anomalies', []) || [];
      const filteredAnomalies = util.getFilteredAnomaliesFromTestSquare(updatedAnomalies, testSquareAnomalies);
      const isSelectedAnomalyUpdateNeeded = (!isEmpty(updatedAnomalies) && state.selectedFacet !== '' && !isEmpty(state.facets));
      const facetWithFilteredAnomalies = isSelectedAnomalyUpdateNeeded
        ? util.getFacetWithFilteredAnomalies(state.facets, state.selectedFacet, updatedAnomalies) : {};
      const assessStatus = util.getRegenerateReportAssessStatus(
        get(payload, 'OrderID', ''),
        get(payload, 'AssessStatus', ''),
      );
      const rejectedQcStructuresOnOrderLoad = util.getQcStructureCapturedValues(get(payload, 'OrderID', ''), get(payload, 'Structures', []));
      return {
        ...state,
        anomalies: updatedAnomalies,
        selectedConfidenceFactor: confidenceFactor,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS]: false },
        ...util.mapAdjusterDetails(payload),
        facetAnomaliesSubset: filteredAnomalies,
        assessStatus,
        selectedAnomalyId: isSelectedAnomalyUpdateNeeded ? util.getInitialAnomalyId({
          ...facetWithFilteredAnomalies,
          anomalyList: facetWithFilteredAnomalies && facetWithFilteredAnomalies.anomalyList ? facetWithFilteredAnomalies.anomalyList
            .filter((anomaly) => (get(facetWithFilteredAnomalies, 'testsquare.anomalies', []) || []).includes(anomaly.anomalyId)) : [],
        }) : state.selectedAnomalyId,
        multiStructure: {
          isMultiStructure: get(payload, 'IsMultiStructure', false),
          structures: get(payload, 'Structures', []),
        },
        rejectedQcStructures: rejectedQcStructuresOnOrderLoad,
        isAssessLite,
        isD2mOrder,
        isOrderPlacedWithMeasurementFeeProduct,
        currentTab: isAssessLite ? TABS.DETAILS_LITE : TABS.DETAILS,
        createdAnnotationToEdit: {},
      };
    }

    /* fetch facets */
    case FETCH_ROOF_FACETS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ROOF_FACETS]: false },
        error: { ...state.error, [FETCH_ROOF_FACETS]: payload },
        hideQcPanel: true,
      };
    case GET_QC_DETAILS_FAILED:
      return {
        ...state,
        errorToastMessage: 'qcPanel.LoadFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        hideQcPanel: true,
      };
    case SET_CURRENT_TAB: {
      const isAssessLite = get(state, 'isAssessLite', false);
      const resetGalleryImages = isAssessLite && (state.currentTab === TABS.GALLERY || payload === TABS.GALLERY);
      const selectedImage = resetGalleryImages ? null : state.selectedImage;
      const lastSelectedImage = resetGalleryImages ? null : state.lastSelectedImage;
      const appliedGalleryImageFilters = resetGalleryImages
        ? util.clearGalleryImageFilters(state.appliedGalleryImageFilters)
        : state.appliedGalleryImageFilters;
      return {
        ...state,
        currentTab: payload,
        lastSelectedImage,
        selectedImage,
        appliedGalleryImageFilters,
        createdAnnotationToEdit: {},
      };
    }
    case SET_CURRENT_NOTES_TAB:
      return {
        ...state,
        currentNotesTab: payload,
      };
    case FETCH_ROOF_FACETS_SUCCESS: {
      const facetTestSquareDisabledMap = { ...state.facetTestSquareDisabledMap };
      const featureFlags = (get(payload, 'featureFlags', []) || []);
      payload.facets.forEach((facet) => {
        Object.assign(facetTestSquareDisabledMap, {
          [facet.facetId]: !featureFlags.includes('TEST_SQUARE_SUPPORTED') || facet.isTestsquareDisabled || isEmpty(facet.testsquare),
        });
      });
      const updatedAnomalies = util.getUpdatedAnomaliesFromFacets(payload.facets, state.selectedConfidenceFactor, state.assessAppSettings);
      const testSquareAnomalies = get(state, 'testSquareData.anomalies', []) || [];
      const filteredAnomalies = util.getFilteredAnomaliesFromTestSquare(updatedAnomalies, testSquareAnomalies);
      const isSelectedAnomalyUpdateNeeded = (!isEmpty(updatedAnomalies) && state.selectedFacet !== '' && !isEmpty(payload.facets));
      const facetWithFilteredAnomalies = isSelectedAnomalyUpdateNeeded
        ? util.getFacetWithFilteredAnomalies(payload.facets, state.selectedFacet, updatedAnomalies) : {};

      return {
        ...state,
        facets: payload.facets.map((x) => ({
          ...x,
          decision: x.decision && x.decision !== '' ? x.decision : 'UNDECIDED',
        })),
        currentView: {
          zoom: 1,
          rotation: 0,
          lonLat: {
            lon: payload.roof.roofOutline.centroid.coordinates[0],
            lat: payload.roof.roofOutline.centroid.coordinates[1],
          },
        },
        roofBoundingBox: payload.roof.roofOutline.boundingBox,
        buckets: util.getBucketsForFacets(payload.facets),
        facetPolygons: util.getFacetPolygons(payload.facets),
        anomalies: updatedAnomalies,
        labelAssets: util.getLabelAssetsFromFacets(payload.facets),
        roofId: payload.roof.roofId,
        roofDecision: {
          ...state.roofDecision,
          [payload.roof.roofId]: ROOF_DECISION[get(payload, 'roof.decision', 'UNDECIDED')],
        },
        roofPolygon: util.getRoofPolygon(payload),
        featureFlags,
        loading: { ...state.loading, [FETCH_ROOF_FACETS]: false },
        error: { ...state.error, [FETCH_ROOF_FACETS]: false },
        facetTestSquareDisabledMap,
        facetAnomaliesSubset: filteredAnomalies,
        selectedAnomalyId: isSelectedAnomalyUpdateNeeded ? util.getInitialAnomalyId({
          ...facetWithFilteredAnomalies,
          anomalyList: facetWithFilteredAnomalies && facetWithFilteredAnomalies.anomalyList ? facetWithFilteredAnomalies.anomalyList
            .filter((anomaly) => (get(facetWithFilteredAnomalies, 'testsquare.anomalies', []) || []).includes(anomaly.anomalyId)) : [],
        }) : state.selectedAnomalyId,
        facetImages: [],
      };
    }
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          ...payload,
        },
      };
    case SAVE_FACET_REVIEW_DECISION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_REVIEW_DECISION]: false },
        errorToastMessage: 'workflowpanel.decisionSaveError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SAVE_FACET_REVIEW_DECISION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_REVIEW_DECISION]: false },
        facets: state.facets.map((facet) => {
          if (facet.facetAliasName === payload.facetAliasName) {
            return {
              ...facet,
              decision: payload.decision,
              includeInReport: payload.includeInReport,
            };
          }
          return facet;
        }),
        labelAssets: {
          ...state.labelAssets,
          data: state.labelAssets.data.map((facet) => {
            if (facet.properties.facetId === payload.facetId) {
              return {
                ...facet,
                properties: {
                  ...facet.properties,
                  decision: payload.decision,
                },
                style: {
                  ...facet.style,
                  text: {
                    ...facet.style.text,
                    backgroundImage: util.getBackgroundImage(payload.decision),
                  },
                },
              };
            }
            return facet;
          }),
        },
      };
    case SET_SELECTED_FACET: {
      const anomalyIdsMap = state.anomalies.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
      const testSquareAnomalies = get(payload, 'testSquare.data.anomalies', []) || [];
      const filteredAnomalies = testSquareAnomalies.filter((anomaly) => anomalyIdsMap.includes(anomaly));
      const testSquareErrorCode = get(payload, 'testSquare.testSquareError', null);
      const testSquareData = get(payload, 'testSquare.data', null);
      const testSquareError = testSquareErrorCode || (testSquareData === null ? 62300 : null);
      return {
        ...state,
        selectedFacet: payload.facetId,
        selectedAnomalyId: payload.selectedAnomalyId,
        testSquareData,
        facetAnomaliesSubset: !payload.testSquare.isDisabled && filteredAnomalies.length
          ? filteredAnomalies : [],
        testSquareError,
        createdAnnotationToEdit: {},
      };
    }
    /* fetch gallery */
    case FETCH_ADJUSTER_DETAILS_GALLERY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS_GALLERY]: false },
        errorToastMessage: 'adjuster.claimsDetailError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case FETCH_ADJUSTER_DETAILS_GALLERY_SUCCESS: {
      const falconTags = util.getGalleryFalconTags(get(payload, 'images', []));
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS_GALLERY]: false },
        galleryImages: get(payload, 'images', []).map((image) => ({
          urn: image.imageURN,
          name: image.imageName,
          metadata: null,
          orientation: image.customMeta.orientation,
          tags: image.tags,
          utcTags: image.meta.utcTags,
          utcReason: image.meta.utcReason,
        })),
        falconTags: util.getOrderedFalconTags([...falconTags]),
        tags: util.combineTagArrays(util.parseFalconTags(get(payload, 'images', [])), state.tags),
      };
    }
    /* save image rotation data */
    case SAVE_ROTATION_FOR_GALLERY_IMAGES_SUCCESS: {
      const orientation = get(payload, 'customMeta.orientation', 0);
      const imageURN = get(payload, 'imageURN', '');
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ROTATION_FOR_GALLERY_IMAGES]: false },
        selectedImageOrientation: orientation,
        galleryImages: util.getUpdatedOrientationForGalleryImages(
          { orientation, imageURN },
          state.galleryImages,
        ),
        filteredGalleryImages: util.getUpdatedOrientationForGalleryImages(
          { orientation, imageURN },
          state.filteredGalleryImages,
        ),
        riskShot: util.getUpdatedOrientationForRiskShotImages(
          { orientation, imageURN },
          state.riskShot,
        ),
      };
    }
    case SAVE_ROTATION_FOR_GALLERY_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ROTATION_FOR_GALLERY_IMAGES]: false },
        errorToastMessage: 'adjuster.saveGalleryImagesOrientation',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* fetch gallery image metadata */
    case FETCH_GALLERY_IMAGE_METADATA_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_GALLERY_IMAGE_METADATA]: false },
        error: { ...state.error, [FETCH_GALLERY_IMAGE_METADATA]: payload },
      };
    case FETCH_GALLERY_IMAGE_METADATA_SUCCESS: {
      const filteredGalleryImages = util.getUpdatedGalleryImages(payload, state.filteredGalleryImages);
      return {
        ...state,
        loading: { ...state.loading, [FETCH_GALLERY_IMAGE_METADATA]: false },
        galleryImages: util.getUpdatedGalleryImages(payload, state.galleryImages),
        filteredGalleryImages,
      };
    }

    /* fetch soft metal images */
    case FETCH_SOFT_METAL_IMAGES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_SOFT_METAL_IMAGES]: false },
        softMetalImages: get(payload, 'data.images', []).map((image) => ({
          urn: image.imageURN,
          name: image.imageName,
          metadata: null,
        })),
      };
    case FETCH_SOFT_METAL_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_SOFT_METAL_IMAGES]: false },
        errorToastMessage: 'workflowpanel.softMetalsError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    /* fetch soft metal image metadata */
    case FETCH_SOFT_METAL_IMAGE_METADATA_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_IMAGE_METADATA]: false },
        softMetalImages: util.getUpdatedGalleryImages(payload, state.softMetalImages),
      };
    case FETCH_FACET_IMAGE_METADATA_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_IMAGE_METADATA]: false },
        error: { ...state.error, [FETCH_FACET_IMAGE_METADATA]: payload },
      };
    case FETCH_ANOMALY_IMAGE_METADATA_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_IMAGE_METADATA]: false },
        anomalyImages: util.getUpdatedGalleryImages(payload, state.anomalyImages),
      };
    /* fetch tags */
    case FETCH_ADJUSTER_DETAILS_TAGS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS_TAGS]: false },
        tags: util.combineTagArrays(state.tags, get(payload, 'images', [])),
        customTags: get(payload, 'images', []),
      };
    case FETCH_ADJUSTER_DETAILS_TAGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS_TAGS]: false },
        errorToastMessage: 'adjuster.claimsDetailError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* fetch notes */
    case FETCH_ADJUSTER_DETAILS_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS_NOTES]: false },
        notes: payload && payload.images ? payload.images : [],
      };
    case FETCH_ADJUSTER_DETAILS_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ADJUSTER_DETAILS_NOTES]: false },
        errorToastMessage: 'adjuster.claimsDetailError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* fetch facet notes */
    case FETCH_FACET_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_NOTES]: false },
        facetNotes: payload && payload.facets ? payload.facets : [],
      };
    case FETCH_FACET_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_NOTES]: false },
        errorToastMessage: 'adjuster.claimsNotesDetailError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* fetch facet test square notes */
    case FETCH_FACET_TEST_SQUARE_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_TEST_SQUARE_NOTES]: false },
        facetTestSquareNotes: payload && payload.testSquares ? payload.testSquares : [],
      };
    case FETCH_FACET_TEST_SQUARE_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_TEST_SQUARE_NOTES]: false },
        errorToastMessage: 'adjuster.claimsTestSquareNotesDetailError',
        errorCode: payload.errorCode,
        showErrorToast: true,
      };

    /* save tags */
    case SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS:
      return {
        ...state,
        tags: util.addTags(state.tags, payload.imageUrn, payload.imageTag),
        customTags: util.addCustomTags(state.customTags, payload.imageUrn, payload.imageTag),
        loading: { ...state.loading, [SAVE_ADJUSTER_DETAILS_TAGS]: false },
      };
    case SAVE_ADJUSTER_DETAILS_TAGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ADJUSTER_DETAILS_TAGS]: false },
        errorToastMessage: 'statusbar.tagSaveError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* save facet report inclusion */
    case SAVE_FACET_REPORT_INCLUSION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_REPORT_INCLUSION]: false },
      };
    case SAVE_FACET_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_REPORT_INCLUSION]: false },
        errorToastMessage: 'statusbar.facetReportInclusionError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SET_FACET_REPORT_INCLUSION:
      return {
        ...state,
        facets: payload,
      };
    /* fetch images included in report */
    case FETCH_REPORT_INCLUDED_IMAGES_SUCCESS:
      return {
        ...state,
        reportIncludedImages: payload && payload.ReferenceID ? payload.ReferenceID : [],
        loading: { ...state.loading, [FETCH_REPORT_INCLUDED_IMAGES]: false },
      };
    case FETCH_REPORT_INCLUDED_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_REPORT_INCLUDED_IMAGES]: false },
        errorToastMessage: 'statusbar.fetchReportIncludedImages',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    /* save image report inclusion */
    case SAVE_IMAGE_REPORT_INCLUSION_SUCCESS:
      return {
        ...state,
        reportIncludedImages: payload
          && payload.ReferenceID && [...state.reportIncludedImages, ...payload.ReferenceID],
        loading: { ...state.loading, [SAVE_IMAGE_REPORT_INCLUSION]: false },
      };
    case SAVE_IMAGE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_IMAGE_REPORT_INCLUSION]: false },
        errorToastMessage: 'statusbar.imageReportInclusionError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    /* delete image report inclusion */
    case DELETE_IMAGE_REPORT_INCLUSION_SUCCESS: {
      const { referenceIds } = payload;
      return {
        ...state,
        reportIncludedImages: [...state.reportIncludedImages].filter(
          (image) => !referenceIds.includes(image),
        ),
        loading: { ...state.loading, [DELETE_IMAGE_REPORT_INCLUSION]: false },
      };
    }
    case DELETE_IMAGE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_IMAGE_REPORT_INCLUSION]: false },
        errorToastMessage: 'statusbar.imageReportRemovingError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    /* delete tags */
    case DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS:
      return {
        ...state,
        tags: util.removeTags(state.tags, payload.imageUrn, payload.imageTag),
        loading: { ...state.loading, [DELETE_ADJUSTER_DETAILS_TAGS]: false },
        customTags: util.removeCustomTags(state.customTags, payload.imageUrn, payload.imageTag),
      };
    case DELETE_ADJUSTER_DETAILS_TAGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_ADJUSTER_DETAILS_TAGS]: false },
        errorToastMessage: 'statusbar.tagDeleteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* save notes */
    case SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ADJUSTER_DETAILS_NOTES]: false },
      };
    case SAVE_ADJUSTER_DETAILS_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ADJUSTER_DETAILS_NOTES]: false },
        errorToastMessage: 'statusbar.noteSaveError',
        errorCode: payload.errorCode,
        showErrorToast: true,
      };

    /* save facet notes */
    case SAVE_FACET_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_NOTES]: false },
      };
    case SAVE_FACET_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_NOTES]: false },
        errorToastMessage: 'statusbar.noteSaveError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* delete notes */
    case DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_ADJUSTER_DETAILS_NOTES]: false },
      };
    case DELETE_ADJUSTER_DETAILS_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_ADJUSTER_DETAILS_NOTES]: false },
        errorToastMessage: 'statusbar.noteDeleteError',
        errorCode: payload.errorCode,
        showErrorToast: true,
      };

    /* delete facet notes */
    case DELETE_FACET_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_FACET_NOTES]: false },
      };
    case DELETE_FACET_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_FACET_NOTES]: false },
        errorToastMessage: 'statusbar.noteDeleteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* update notes */
    case UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_ADJUSTER_DETAILS_NOTES]: false },
      };
    case UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_ADJUSTER_DETAILS_NOTES]: false },
        errorToastMessage: 'statusbar.noteUpdateError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* update facet notes */
    case UPDATE_FACET_NOTES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_FACET_NOTES]: false },
      };
    case UPDATE_FACET_NOTES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_FACET_NOTES]: false },
        errorToastMessage: 'statusbar.noteUpdateError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* post reports */
    case POST_REPORT_SUCCESS:
      return {
        ...state,
        error: { ...state.error, [POST_REPORT]: false },
        disableGenerateReport: isSharedUrl(),
      };
    case POST_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [POST_REPORT]: false },
        errorToastMessage: 'report.submitError',
        errorCode: payload.errorCode,
        showErrorToast: true,
        error: { ...state.error, [POST_REPORT]: payload },
      };
    case POLL_REPORT_SUCCESS:
      return {
        ...state,
        report: state.orderId === payload.orderId && payload.data,
        loading: { ...state.loading, [POST_REPORT]: false },
        error: { ...state.error, [`${POLL_REPORT}_${payload.reportId}`]: false },
      };
    case POLL_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [POST_REPORT]: false },
        errorToastMessage: 'report.submitError',
        errorCode: payload.error.errorCode,
        showErrorToast: true,
        error: { ...state.error, [`${POLL_REPORT}_${payload.reportId}`]: payload.error },
      };
    case SET_ORDER_ID:
      return {
        ...state,
        orderId: payload,
      };
    case SHOW_REPORT_FAILURE:
      return {
        ...state,
        errorToastMessage: 'report.unableDownload',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    /* set report generator dependents */
    case SET_REPORT_ORDER_ID:
      return {
        ...state,
        reportOrderId: payload,
      };
    case INCREMENT_GENERATE_REPORT_DEPENDENTS:
      return {
        ...state,
        reportGeneratorCount: state.reportGeneratorCount + 1,
      };
    case DECREMENT_GENERATE_REPORT_DEPENDENTS:
      return {
        ...state,
        reportGeneratorCount: state.reportGeneratorCount > 0 ? state.reportGeneratorCount - 1 : 0,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
      };
    case SET_SELECTED_IMAGE:
      return {
        ...state,
        lastSelectedImage: state.selectedImage,
        selectedImage: payload,
        createdAnnotationToEdit: {},
      };
    case FETCH_FACET_BASE_IMAGE_SUCCESS:
      return {
        ...state,
        baseImage: payload,
        loading: { ...state.loading, [FETCH_FACET_BASE_IMAGE]: false },
        error: { ...state.error, [FETCH_FACET_BASE_IMAGE]: false },
      };
    case FETCH_FACET_BASE_IMAGE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_BASE_IMAGE]: false },
        error: { ...state.error, [FETCH_FACET_BASE_IMAGE]: payload },
        errorToastMessage: 'adjuster.baseImage',
        errorCode: payload && payload.errorCode,
        showErrorToast: true,
        hideQcPanel: true,
      };
    case CLOSE_ERROR_TOAST: {
      return {
        ...state,
        showErrorToast: false,
      };
    }
    case FETCH_IW_IMAGE_METADATA_SUCCESS:
      return {
        ...state,
        metadata: payload,
        loading: { ...state.loading, [FETCH_IW_IMAGE_METADATA]: false },
        error: { ...state.error, [FETCH_IW_IMAGE_METADATA]: false },
      };
    case FETCH_IW_IMAGE_METADATA_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_IW_IMAGE_METADATA]: false },
        error: { ...state.error, [FETCH_IW_IMAGE_METADATA]: payload },
      };
    case FETCH_FACET_IMAGES_SUCCESS: {
      return {
        ...state,
        facetImages: [...state.facetImages, payload.response],
        loading: {
          ...state.loading,
          facetImages: {
            ...(state.loading.facetImages || {}),
            [payload.response.facetId]: false,
          },
        },
      };
    }
    case FETCH_FACET_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FACET_IMAGES]: false },
        errorToastMessage: 'adjuster.facetImages',
        errorCode: payload && payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case FETCH_RISK_SHOT_SUCCESS:
      return {
        ...state,
        riskShot: {
          ...state.riskShot,
          [payload.structureId]: payload.riskShot,
        },
        loading: { ...state.loading, [FETCH_RISK_SHOT]: false },
        error: { ...state.error, [FETCH_RISK_SHOT]: false },
      };
    case FETCH_RISK_SHOT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_RISK_SHOT]: false },
        error: { ...state.error, [FETCH_RISK_SHOT]: payload },
      };
    case FETCH_ANOMALY_METADATA_SUCCESS:
      return {
        ...state,
        selectedAnomalyMetadata: payload,
        loading: { ...state.loading, [FETCH_ANOMALY_METADATA]: false },
        error: { ...state.error, [FETCH_ANOMALY_METADATA]: false },
      };
    case FETCH_ANOMALY_METADATA_FAILURE:
      return {
        ...state,
        errorToastMessage: 'workflowpanel.anomalyImage',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        loading: { ...state.loading, [FETCH_ANOMALY_METADATA]: false },
        error: { ...state.error, [FETCH_ANOMALY_METADATA]: payload },
      };
    case SET_ANOMALY_DECISION: {
      const { facetId, anomalyId, isRawImageryWorkflow } = payload;
      return {
        ...state,
        rawImageAnomalies: isRawImageryWorkflow ? state.rawImageAnomalies.map((a) => {
          if (a.anomalyId === anomalyId) {
            return {
              ...payload,
            };
          }
          return a;
        }) : state.rawImageAnomalies,
        facets: state.facets.map((facet) => {
          if (facet.facetId === facetId) {
            return {
              ...facet,
              anomalyList: facet.anomalyList.map((a) => {
                if (a.anomalyId === anomalyId) {
                  return {
                    ...payload,
                  };
                }
                return a;
              }),
            };
          }
          return facet;
        }),
      };
    }
    case SAVE_ANOMALY_DECISION_SUCCESS: {
      const { response, anomalyId, facetId } = payload;
      if (response && response.anomalyId && response.roofId) {
        return {
          ...state,
          facets: state.facets.map((facet) => {
            if (facet.facetId === facetId) {
              return {
                ...facet,
                anomalyList: facet.anomalyList.map((a) => {
                  if (a && a.anomalyId === anomalyId) {
                    return response;
                  }
                  return a;
                }),
              };
            }
            return facet;
          }),
          rawImageAnomalies: payload.isRawImageryWorkflow ? state.isRawImageryWorkflow.map((anomaly) => {
            if (response.anomalyId === anomaly.data[0].properties.anomalyId) {
              return {
                ...anomaly,
                data: [
                  {
                    ...anomaly.data[0],
                    properties: {
                      ...anomaly.data[0].properties,
                      status: response.decision,
                      includeInReport: response.includeInReport,
                    },
                  },
                ],
              };
            }
            return anomaly;
          }) : state.rawImageAnomalies,
          anomalies: state.anomalies.map((anomaly) => {
            if (response.anomalyId === anomaly.data[0].properties.anomalyId) {
              return {
                ...anomaly,
                data: [
                  {
                    ...anomaly.data[0],
                    properties: {
                      ...anomaly.data[0].properties,
                      status: response.decision,
                      includeInReport: response.includeInReport,
                    },
                  },
                ],
              };
            }
            return anomaly;
          }),
        };
      }
      return state;
    }
    case SAVE_ANOMALY_DECISION_FAILURE:
      return {
        ...state,
        errorToastMessage: 'workflowpanel.anomalyDecisionSaveError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SET_WORKFLOW_ANOMALY_ID:
      return {
        ...state,
        selectedAnomalyId: payload,
      };
    case ADD_USER_ANOMALY_SUCCESS: {
      const selectedFacet = state.facets.find((facet) => facet.facetId === payload.newAnomaly.facetId);
      const facetTestSquareData = !isEmpty(selectedFacet.testsquare) ? {
        ...selectedFacet.testsquare,
        anomalies: Array.isArray(selectedFacet.testsquare.anomalies)
          ? [...selectedFacet.testsquare.anomalies, payload.newAnomaly.anomalyId] : [payload.newAnomaly.anomalyId],
      } : selectedFacet.testsquare;
      return {
        ...state,
        rawImageAnomalies: [...state.rawImageAnomalies, ...(payload.isRawImageryWorkflow ? [payload.newAnomaly] : [])],
        facets: state.facets.map((facet) => (facet.facetId === payload.newAnomaly.facetId
          ? {
            ...facet,
            anomalyList: [...facet.anomalyList, payload.newAnomaly],
            testsquare: facetTestSquareData,
          }
          : facet)),
        anomalies: [...state.anomalies, util.getAnnotationFromAnomaly(payload.newAnomaly)],
        facetAnomaliesSubset: !state.facetTestSquareDisabledMap[state.selectedFacet] ? [...state.facetAnomaliesSubset, payload.newAnomaly.anomalyId] : [],
        testSquareData: facetTestSquareData,
      };
    }
    case ADD_USER_ANOMALY_FAILURE:
      return {
        ...state,
        errorToastMessage: 'workflowpanel.addUserAnomalyError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case DELETE_USER_ANOMALY:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_USER_ANOMALY]: true },
      };
    case DELETE_USER_ANOMALY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_USER_ANOMALY]: false },
        rawImageAnomalies: payload.isRawImageryWorkflow ? state.rawImageAnomalies.filter(
          (anomaly) => anomaly.anomalyId !== payload.anomalyId,
        ) : state.rawImageAnomalies,
        facets: state.facets.map((facet) => {
          if (facet.facetId === payload.facetId) {
            return {
              ...facet,
              anomalyList: [
                ...facet.anomalyList.filter((anomaly) => ![payload.anomalyId, payload.mosaicAnomalyId].includes(anomaly.anomalyId)),
              ],
            };
          }
          return facet;
        }),
        anomalies: state.anomalies.filter(
          (anomaly) => anomaly.data[0].properties.anomalyId !== payload.mosaicAnomalyId, // mosaicAnomalyId is id received from api response
        ),
      };
    case DELETE_USER_ANOMALY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_USER_ANOMALY]: false },
        errorToastMessage: 'workflowpanel.userAnomalyDeleteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SAVE_ROOF_DECISION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ROOF_DECISION]: false },
      };
    case SAVE_ROOF_DECISION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ROOF_DECISION]: false },
        errorToastMessage: 'reportPanel.saveRoofDecisionError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SET_ROOF_DECISION:
      return {
        ...state,
        roofDecision: {
          ...state.roofDecision,
          [payload.roofId]: get(payload, 'decision', 'Undecided'),
        },
      };
    case RESET_ADJUSTER:
      return { ...initialState };
    case SET_SELECTED_ANOMALY_VIEW:
      return {
        ...state,
        selectedAnomalyView:
          payload && payload.data
            ? util.getWorkFlowPanelViewFromAnomaly(
              payload.data[0].geometries[0],
            )
            : {},
      };
    case SET_USER:
      return {
        ...state,
        user: util.userNameToLowerCase(payload),
      };
    case FETCH_ROOF_NOTE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ROOF_NOTE]: false },
        roofNote: { ...state.roofNote, [payload.roofId]: get(payload, 'response.roofs[0].notes[0]', undefined) },
      };
    case FETCH_ROOF_NOTE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ROOF_NOTE]: false },
        errorToastMessage: 'reportPanel.fetchRoofNoteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SAVE_ROOF_NOTE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ROOF_NOTE]: false },
        roofNote: { ...state.roofNote, [payload.roofId]: get(payload, 'response.roofs[0].notes[0]', undefined) },
      };
    case SAVE_ROOF_NOTE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_ROOF_NOTE]: false },
        errorToastMessage: 'reportPanel.saveRoofNoteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case UPDATE_ROOF_NOTE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_ROOF_NOTE]: false },
        roofNote: { ...state.roofNote, [payload.roofId]: get(payload, 'response.roofs[0].notes[0]', undefined) },
      };
    case UPDATE_ROOF_NOTE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_ROOF_NOTE]: false },
        errorToastMessage: 'reportPanel.updateRoofNoteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case DELETE_ROOF_NOTE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_ROOF_NOTE]: false },
        roofNote: { ...state.roofNote, [payload.roofId]: undefined },
      };
    case DELETE_ROOF_NOTE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_ROOF_NOTE]: false },
        errorToastMessage: 'reportPanel.deleteRoofNoteError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SET_SIDE_PANEL_WIDTH:
      return {
        ...state,
        sidePanelWidth: payload,
      };
    case SHOW_ERROR_TOAST:
      return {
        ...state,
        errorToastMessage: payload.message,
        // Pass null if you want the toast to stay forever, if nothing is sent it would take default as 10000.
        toastAutoHideDuration: payload.toastAutoHideDuration !== undefined ? payload.toastAutoHideDuration : state.toastAutoHideDuration,
        severity: 'error',
        showErrorToast: true,
      };
    case SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_NOTE_REPORT_INCLUSION]: false },
      };
    case SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_NOTE_REPORT_INCLUSION]: false },
        errorToastMessage: 'statusbar.noteUpdateError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SAVE_IMAGE_NOTE_REPORT_INCLUSION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_IMAGE_NOTE_REPORT_INCLUSION]: false },
      };
    case SAVE_IMAGE_NOTE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_IMAGE_NOTE_REPORT_INCLUSION]: false },
        errorToastMessage: 'statusbar.noteUpdateError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case COMPLETE_REVIEW_FAILURE:
      return {
        ...state,
        errorToastMessage: 'adjuster.completeReviewFailure',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_APPROVE_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.ApprovedFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_REJECT_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.RejectFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_RETIEPOINT_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.RetiepointFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_RESCHEDULE_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.RescheduleFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_RESCHEDULE_SUCCESS:
      return {
        ...state,
        errorToastMessage: 'qcPanel.RescheduleSuccess',
        showErrorToast: true,
        severity: 'success',
      };
    case QC_APPROVED_SUCCESS:
      return {
        ...state,
        errorToastMessage: 'qcPanel.ApprovedSuccess',
        showErrorToast: true,
        severity: 'success',
      };
    case QC_REJECT_SUCCESS:
      return {
        ...state,
        errorToastMessage: 'qcPanel.RejectSuccess',
        showErrorToast: true,
        severity: 'success',
      };
    case QC_RETIEPOINT_SUCCESS:
      return {
        ...state,
        errorToastMessage: 'qcPanel.RetiepointSuccess',
        showErrorToast: true,
        severity: 'success',
      };
    case QC_OPS_CHECKOUT_BY_REPORT_ID_SUCCESS:
      return {
        ...state,
        qcOpsPrimeReport: payload,
        errorToastMessage: 'qcPanel.checkoutByReportIdSuccess',
        showErrorToast: false,
        severity: 'success',
        hideQcPanel: false,
      };
    case QC_OPS_CHECKOUT_BY_REPORT_ID_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.checkoutByReportIdFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        hideQcPanel: true,
      };
    case QC_OPS_CHECKIN_SIMPLE_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.checkInSimpleFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_OPS_REJECT_SIMPLE_NOTES_FAILURE:
      return {
        ...state,
        errorToastMessage: 'qcPanel.rejectSimpleNotesFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case QC_OPS_ASSIGN_SELF:
      return {
        ...state,
        assignQcOpsTiepointingToSelf: payload,
      };
    case FETCH_QC_PANEL_DETAILS_SUCCESS:
      return {
        ...state,
        QcClaimID: payload.ID,
        chartData: payload.claimsDetails.facet_coverage,
        coveragePercentage: payload.overallCoverage,
        qcPanelType: payload.status,
        qcStartTime: payload.startTime,
        qcEndTime: payload.endTime,
        QcNotesList: state.QcNotesList.set(
          payload.orderID,
          payload.notes !== null && payload.notes !== undefined
            ? payload.notes.reverse()
            : [],
        ),
        isQcD2m: payload.isD2m,
        skydioMosaicPresent: get(payload, 'skydioMosaicPresent', false),
      };
    case HANDLE_QC_NOTE_CHANGE:
      return {
        ...state,
        qcNotes: state.qcNotes.set(payload.orderId, payload.notes),
      };
    case HANDLE_QC_REASON_CHANGE:
      return {
        ...state,
        qcReason: payload,
      };
    case HANDLE_QC_REASONS_CHANGE:
      return {
        ...state,
        qcReasons: payload.ischecked ? [...state.qcReasons, payload.value] : state.qcReasons.filter((reason) => reason !== payload.value),
      };
    case HANDLE_QC_REASONS_EMPTY_REQUEST:
      return {
        ...state,
        qcReasons: [],
      };
    case DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DOWNLOAD_SKYDIO_MOSAIC_IMAGE]: false },
        skydioMosaicImageUrl: payload,
      };
    case DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DOWNLOAD_SKYDIO_MOSAIC_IMAGE]: false },
        errorToastMessage: 'qcPanel.skydioImageDownloadFailure',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        skydioMosaicImageUrl: SKYDIO_IMAGE_DOWNLOAD_ERROR,
        showSkydioMosaicImagePanel: false,
      };
    case CLEAR_SKYDIO_MOSAIC_IMAGE_URL:
      return {
        ...state, skydioMosaicImageUrl: '',
      };
    case SET_SHOW_SKYDIO_MOSAIC_IMAGE_PANEL:
      return {
        ...state,
        showSkydioMosaicImagePanel: payload,
      };

    /* display gallery annotations */
    case DISPLAY_GALLERY_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DISPLAY_GALLERY_ANNOTATIONS]: false },
        gallaryAnnotations: get(payload, 'images[0].annotations', []).map((annotation) => ({ ...annotation, geometries: [annotation.geometry] })),
        deleteStatus: !state.loading[DELETE_GALLERY_ANNOTATIONS],
      };
    case DISPLAY_GALLERY_ANNOTATIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DISPLAY_GALLERY_ANNOTATIONS]: false },
        errorToastMessage: 'imagePreview.displayAnnotation',
        severity: 'error',
        showErrorToast: true,
        deleteStatus: false,
      };

    /* save gallery annotation */
    case SAVE_GALLERY_ANNOTATIONS_SUCCESS: {
      const { imageId, annotationId, annotations } = payload;
      const annotationType = get(annotations, '[0].properties.annotationType', '');
      return {
        ...state,
        loading: { ...state.loading, [SAVE_GALLERY_ANNOTATIONS]: false },
        createdAnnotationToEdit: annotationType === 'box' ? {
          imageId,
          annotationId,
        } : {},
        gallaryAnnotations: [...state.gallaryAnnotations,
          ...annotations],
      }; }
    case SAVE_GALLERY_ANNOTATIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_GALLERY_ANNOTATIONS]: false },
        errorToastMessage: 'imagePreview.saveAnnotation',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* update gallery annotation */
    case UPDATE_GALLERY_ANNOTATIONS_SUCCESS: {
      const updatedAnnotation = get(payload, 'annotations[0]', []);
      const annotationType = get(updatedAnnotation, 'properties.annotationType', '');
      const gallaryAnnotations = annotationType === 'box'
        ? state.gallaryAnnotations
        : state.gallaryAnnotations.map((annotation) => {
          if (annotation.annotationId === updatedAnnotation.annotationId) {
            return { ...updatedAnnotation, geometries: [updatedAnnotation.geometry] };
          }
          return annotation;
        });

      return {
        ...state,
        loading: { ...state.loading, [UPDATE_GALLERY_ANNOTATIONS]: false },
        gallaryAnnotations,
        createdAnnotationToEdit: {},
      };
    }
    case UPDATE_GALLERY_ANNOTATIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_GALLERY_ANNOTATIONS]: false },
        errorToastMessage: 'imagePreview.saveAnnotation',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* delete gallery annotation */
    case DELETE_GALLERY_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_GALLERY_ANNOTATIONS]: false },
        deleteStatus: false,
      };
    case DELETE_GALLERY_ANNOTATIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_GALLERY_ANNOTATIONS]: false },
        errorToastMessage: 'imagePreview.deleteAnnotation',
        deleteStatus: false,
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    case SET_APPLIED_GALLERY_IMAGE_FILTERS: {
      const customTags = uniq(flatten(get(state, 'customTags', []).map((image) => image.tags)));
      const activeFiltersTags = customTags.filter((tag) => payload[tag]);
      // filtering the gallery images based on applied filters
      const currentTabGalleryImages = state.isAssessLite ? util.filterGalleryImagesByTab(state.galleryImages, state.currentTab) : state.galleryImages;
      const filteredGalleryImages = currentTabGalleryImages.filter((image) => {
        const currentImageObject = state.customTags.find((item) => item.imageID === image.urn);
        return (currentImageObject && intersection(activeFiltersTags, currentImageObject.tags).length > 0) || isEmpty(activeFiltersTags);
      });
      const selectedImage = state.selectedImage || state.lastSelectedImage;
      const isSelectedImageInFiltered = selectedImage && filteredGalleryImages.find((image) => selectedImage === image.urn) !== undefined;
      return {
        ...state,
        appliedGalleryImageFilters: payload,
        filteredGalleryImages,
        currentTabGalleryImages,
        lastSelectedImage: state.selectedImage,
        selectedImage: isSelectedImageInFiltered ? selectedImage : get(filteredGalleryImages[0], 'urn', undefined),
      };
    }
    case TEST_SQUARE_STATUS_UPDATE: {
      return {
        ...state,
        loading: { ...state.loading, [TEST_SQUARE_STATUS_UPDATE]: true },
      }; }
    /* test square status */
    case TEST_SQUARE_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [TEST_SQUARE_STATUS_UPDATE]: false },
        facetTestSquareDisabledMap: {
          ...state.facetTestSquareDisabledMap,
          [payload.facetId]: !state.facetTestSquareDisabledMap[payload.facetId],
        },
        facetAnomaliesSubset: state.facetTestSquareDisabledMap[payload.facetId] ? get(state, 'testSquareData.anomalies', []) || [] : [],
        facets: state && state.facets && state.facets.map((facet) => (facet.facetId === payload.facetId
          ? {
            ...facet,
            isTestsquareDisabled: !state.facetTestSquareDisabledMap[payload.facetId],
          }
          : facet)),
      };
    case TEST_SQUARE_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [TEST_SQUARE_STATUS_UPDATE]: false },
        errorToastMessage: 'workflowpanel.testSquareUpdate',
        severity: 'error',
        showErrorToast: true,
        facetTestSquareDisabledMap: {
          ...state.facetTestSquareDisabledMap,
          [payload.facetId]: state.facetTestSquareDisabledMap[payload.facetId],
        },
      };

    /* test square location update */
    case TEST_SQUARE_LOCATION_UPDATE_SUCCESS: {
      const updatedFacetsArray = state.facets.map((facet) => (facet.facetId === payload.facetId
        ? {
          ...facet,
          testsquare: { ...facet.testsquare, ...payload },
        }
        : facet));
      const facetWithFilteredAnomalies = util.getFacetWithFilteredAnomalies(updatedFacetsArray, state.selectedFacet, state.anomalies);
      return {
        ...state,
        loading: { ...state.loading, [TEST_SQUARE_LOCATION_UPDATE]: false },
        testSquareData: { ...state.testSquareData, ...payload },
        facets: updatedFacetsArray,
        facetAnomaliesSubset: payload.anomalies,
        selectedAnomalyId: util.getInitialAnomalyId({
          ...facetWithFilteredAnomalies,
          anomalyList: facetWithFilteredAnomalies.anomalyList
            .filter((anomaly) => (get(facetWithFilteredAnomalies, 'testsquare.anomalies', []) || []).includes(anomaly.anomalyId)),
        }),
        testSquareLocationApiFail: undefined,
      };
    }
    case TEST_SQUARE_LOCATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [TEST_SQUARE_LOCATION_UPDATE]: false },
        errorToastMessage: 'adjuster.testSquareLocationUpdateError',
        severity: 'error',
        showErrorToast: true,
        testSquareLocationApiFail: !state.testSquareLocationApiFail,
      };

    /* App settings */
    case FETCH_ASSESS_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        assessAppSettings: payload,
        loading: { ...state.loading, [FETCH_ASSESS_APP_SETTINGS]: false },
      };
    case FETCH_ASSESS_APP_SETTINGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ASSESS_APP_SETTINGS]: false },
        showErrorToast: true,
        errorToastMessage: 'adjuster.assessAppSettingsError',
        errorCode: payload.errorCode,
      };

    /* TOGGLE_ANOMALY_INCLUDE_IN_REPORT */
    case TOGGLE_ANOMALY_INCLUDE_IN_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [TOGGLE_ANOMALY_INCLUDE_IN_REPORT]: false },
        anomalies: state.anomalies.map((anomaly) => {
          const anomalyId = get(anomaly, 'data[0].properties.anomalyId');
          if (anomalyId !== payload.id) return anomaly;
          return {
            ...anomaly,
            data: [{
              ...anomaly.data[0],
              properties: {
                ...anomaly.data[0].properties,
                includeInReport: payload.includeInReport,
              },
            }],
          };
        }),
      };
    case TOGGLE_ANOMALY_INCLUDE_IN_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [TOGGLE_ANOMALY_INCLUDE_IN_REPORT]: false },
        errorToastMessage: 'workflowpanel.toggleAnomalyIncludeInReportError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    // export images
    case EXPORT_ALL_IMAGES:
    case EXPORT_REPORT_INCLUDED_IMAGES:
      return {
        ...state,
        loading: { ...state.loading, [EXPORT_IMAGERY]: true },
      };
    case EXPORT_ALL_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [EXPORT_IMAGERY]: false },
        errorToastMessage: 'exportImagery.unableToDownloadImages',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case EXPORT_REPORT_INCLUDED_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [EXPORT_IMAGERY]: false },
        errorToastMessage: 'exportImagery.unableToDownloadImages',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case POLL_EXPORT_IMAGES:
      return {
        ...state,
        loading: { ...state.loading, [`${type}_${payload.exportId}`]: true },
      };
    case POLL_EXPORT_IMAGES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [`${POLL_EXPORT_IMAGES}_${payload.exportId}`]: false },
      };
    case POLL_EXPORT_IMAGES_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [EXPORT_IMAGERY]: false,
          [`${POLL_EXPORT_IMAGES}_${payload.exportId}`]: false,
        },
        errorToastMessage: 'exportImagery.unableToDownloadImages',
        errorCode: payload.error.errorCode,
        showErrorToast: true,
        severity: 'error',
      };
    case DOWNLOAD_EXPORTED_IMAGES:
      return {
        ...state,
        loading: { ...state.loading, [`${type}_${payload.exportId}`]: true },
      };
    case DOWNLOAD_EXPORTED_IMAGES_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [`${DOWNLOAD_EXPORTED_IMAGES}_${payload.exportId}`]: false,
          [EXPORT_IMAGERY]: false,
        },
      };
    case DOWNLOAD_EXPORTED_IMAGES_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [`${DOWNLOAD_EXPORTED_IMAGES}_${payload.exportId}`]: false,
          [EXPORT_IMAGERY]: false,
        },
        errorToastMessage: 'exportImagery.unableToDownloadImages',
        errorCode: payload.error.errorCode,
        showErrorToast: true,
        severity: 'error',
      };
    case SAVE_CONFIDENCE_FACTOR_SUCCESS:
    case SAVE_CONFIDENCE_FACTOR_FAILURE: {
      const updatedAnomalies = util.parseAnomaliesFromFacets(state.facets).filter(
        (anomaly) => util.confidenceFactorFilter(anomaly, payload, state.assessAppSettings),
      );
      const anomalyIdsMap = updatedAnomalies.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
      const testSquareAnomalies = get(state, 'testSquareData.anomalies', []) || [];
      const filteredAnomalies = testSquareAnomalies.filter((anomaly) => anomalyIdsMap.includes(anomaly));
      return {
        ...state,
        selectedConfidenceFactor: payload,
        anomalies: updatedAnomalies,
        facetAnomaliesSubset: filteredAnomalies,
      };
    }
    case FETCH_REPORT_VERSIONS_SUCCESS:
      return {
        ...state,
        reportList: payload ? util.mapReportPayload(payload) : [],
      };
    case FETCH_REPORT_VERSIONS_FAILURE:
      return {
        ...state,
        errorToastMessage: 'report.reportVersionsFetchError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SET_FACET_NOTE_REPORT_INCLUSION:
      return {
        ...state,
        facetNotes: [...state.facetNotes.map((facet) => (facet.facetID === payload.facetId
          ? {
            ...facet,
            notes: [
              ...facet.notes.map((note) => (note.noteID === payload.noteId ? { ...note, includeInReport: payload.includeInReport } : note)),
            ],
          }
          : facet))],
      };
    case SET_GALLERY_IMAGE_NOTE_REPORT_INCLUSION:
      return {
        ...state,
        notes: [...state.notes.map((image) => (image.imageID === payload.imageUrn
          ? {
            ...image,
            notes: [
              ...image.notes.map((note) => (note.noteID === payload.noteId ? { ...note, includeInReport: payload.includeInReport } : note)),
            ],
          }
          : image))],
      };
    case SET_TEST_SQUARE_NOTE_REPORT_INCLUSION:
      return {
        ...state,
        facetTestSquareNotes: [...state.facetTestSquareNotes.map((facetTestSquare) => (facetTestSquare.testSquareID === payload.testSquareId
          ? {
            ...facetTestSquare,
            notes: [
              ...facetTestSquare.notes.map((note) => (note.noteID === payload.noteId ? { ...note, includeInReport: payload.includeInReport } : note)),
            ],
          }
          : facetTestSquare))],
      };
    // fetch feature preferences to be opt out
    case FETCH_FEATURE_PREFERENCE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FEATURE_PREFERENCE]: false },
        featurePreferences: payload || [],
      };
    case FETCH_FEATURE_PREFERENCE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FEATURE_PREFERENCE]: false },
        featurePreferences: [],
      };
    /* Drag and move anomaly */
    case UPDATE_USER_DEFINED_ANOMALY_LOCATION_SUCCESS: {
      const anomalyPixelCoords = get(payload, 'properties.pixel_coords', []);
      const anomalyCoords = get(payload, 'geometry.coordinates', []);
      const updatedAnomalies = state.anomalies.map((asset) => {
        const assetData = get(asset, 'data[0]', '');
        if (assetData.properties.anomalyId === payload.anomalyId) {
          return {
            ...asset,
            data: [{
              ...assetData,
              geometries: [{
                coordinates: anomalyCoords,
                type: 'Polygon',
              }],
              properties: {
                ...assetData.properties,
                pixel_coords: anomalyPixelCoords,
              },
            }],
          };
        }
        return asset;
      });

      const selectedFacet = state.facets.find((facet) => facet.facetId === payload.facetId);
      const testSquareGeometry = get(selectedFacet, 'testsquare.geometry', {});
      const facetTestSquare = get(selectedFacet, 'testsquare', null);
      const isAnomalyInsideTestSquare = facetTestSquare ? util.isAnnotationCompletelyOutside(payload.geometry, testSquareGeometry) : false;
      let updateTestSquareAnomalies = [];
      if (facetTestSquare) {
        updateTestSquareAnomalies = isAnomalyInsideTestSquare
          ? [...selectedFacet.testsquare.anomalies.filter((anomalyId) => anomalyId !== payload.anomalyId)]
          : [...selectedFacet.testsquare.anomalies, payload.anomalyId];
      }

      const facetTestSquareData = facetTestSquare ? {
        ...selectedFacet.testsquare,
        anomalies: updateTestSquareAnomalies,
      } : facetTestSquare;

      return {
        ...state,
        loading: { ...state.loading, [UPDATE_USER_DEFINED_ANOMALY_LOCATION]: false },
        anomalies: updatedAnomalies,
        facets: state.facets.map((facet) => (facet.facetId === payload.facetId
          ? {
            ...facet,
            anomalyList: [...facet.anomalyList.map(
              (anomaly) => (anomaly.anomalyId === payload.anomalyId ? { ...anomaly, geometry: payload.geometry } : anomaly),
            )],
            testsquare: facetTestSquareData,
          }
          : facet)),
        testSquareData: facetTestSquareData,
      };
    }
    case UPDATE_USER_DEFINED_ANOMALY_LOCATION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_USER_DEFINED_ANOMALY_LOCATION]: false },
        errorToastMessage: 'adjuster.saveUserAnomalyMovementError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        updateAnomalyLocationApiFail: !state.updateAnomalyLocationApiFail,
      };
    // Save facet annotations
    case SAVE_FACET_ANNOTATION_SUCCESS: {
      const facetId = get(payload, 'facets[0].facetID', '');
      const newAddedAnnotation = get(payload, 'facets[0].annotations', []).map((annotation) => ({ ...annotation, geometries: [annotation.geometry] }));
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_ANNOTATION]: false },
        facetBoxAnnotations: [...state.facetBoxAnnotations, ...newAddedAnnotation],
        createdAnnotationToEdit: {
          facetId,
          annotationId: newAddedAnnotation[0].annotationId,
        },
      };
    }
    case SAVE_FACET_ANNOTATION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_FACET_ANNOTATION]: false },
        errorToastMessage: 'adjuster.saveFacetAnnotationError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    // display facet annotations
    case DISPLAY_FACET_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [DISPLAY_FACET_ANNOTATIONS]: false },
        facetBoxAnnotations: get(payload, 'facets[0].annotations', [])
          .map((annotation) => ({ ...annotation, geometries: [get(annotation, 'geometry', [])] })),
      };
    case DISPLAY_FACET_ANNOTATIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DISPLAY_FACET_ANNOTATIONS]: false },
        errorToastMessage: 'adjuster.displayFacetAnnotations',
        severity: 'error',
        showErrorToast: true,
        facetBoxAnnotations: [],
      };
    /* update facet annotation */
    case UPDATE_FACET_ANNOTATION_SUCCESS: {
      const updatedAnnotationId = get(payload, 'annotations.0.annotationId', '');
      const updatedAnnotationGeometry = get(payload, 'annotations.0.geometry', {});
      const updatedFacetBoxAnnotations = state.facetBoxAnnotations.map((annotation) => {
        if (annotation.annotationId === updatedAnnotationId) {
          return { ...annotation, geometries: [updatedAnnotationGeometry] };
        }
        return annotation;
      });
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_FACET_ANNOTATION]: false },
        facetBoxAnnotations: updatedFacetBoxAnnotations,
      };
    }
    case UPDATE_FACET_ANNOTATION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_FACET_ANNOTATION]: false },
        errorToastMessage: 'adjuster.facetAnnotationUpdateError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case UPDATE_FACET_ANNOTATION_INCL_IN_REPORT:
      return {
        ...state,
      };
    case UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_SUCCESS: {
      const includeInReport = get(payload, 'data.annotation.includeInReport');
      const updatedFacetBoxAnnotations = state.facetBoxAnnotations.map(
        (fb) => (get(fb, 'annotationId') === get(payload, 'annotationId') ? { ...fb, includeInReport } : { ...fb }),
      );
      return {
        ...state,
        facetBoxAnnotations: updatedFacetBoxAnnotations,
      };
    }
    case UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_FAILURE:
      return {
        ...state,
        errorToastMessage: 'imagePreview.saveAnnotation',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    case UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT:
      return {
        ...state,
      };
    case UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_SUCCESS: {
      const includeInReport = get(payload, 'data.annotation.includeInReport');
      const updatedFacetBoxAnnotations = state.gallaryAnnotations.map(
        (ga) => (get(ga, 'annotationId') === get(payload, 'annotationId') ? { ...ga, includeInReport } : { ...ga }),
      );
      return {
        ...state,
        gallaryAnnotations: updatedFacetBoxAnnotations,
      };
    }
    case UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_FAILURE:
      return {
        ...state,
        errorToastMessage: 'imagePreview.saveAnnotation',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    /* delete facet annotation */
    case DELETE_FACET_ANNOTATION_SUCCESS: {
      const updatedFacetBoxAnnotations = state.facetBoxAnnotations.filter((annotation) => annotation.annotationId !== payload.annotationId);
      return {
        ...state,
        loading: { ...state.loading, [DELETE_FACET_ANNOTATION]: false },
        facetBoxAnnotations: updatedFacetBoxAnnotations,
      };
    }
    case DELETE_FACET_ANNOTATION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_FACET_ANNOTATION]: false },
        errorToastMessage: 'adjuster.deleteAnnotationError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SET_FACET_BOX_ANNOTATIONS:
      return {
        ...state,
        facetBoxAnnotations: payload,
      };
    /* workflow map navigation */
    case ENABLE_WORKFLOW_NAVIGATION:
      return {
        ...state,
        workflowPanelNavigationControl: {
          disablePitchAndRotation: false,
          disableZoom: false,
          disablePan: false,
        },
      };
    case DISABLE_WORKFLOW_NAVIGATION:
      return {
        ...state,
        workflowPanelNavigationControl: {
          disablePitchAndRotation: true,
          disableZoom: true,
          disablePan: true,
        },
      };
    case SHOW_WORKFLOW_FACET_POLYGON:
      return {
        ...state,
        workflowPanelDisplayFacetPolygon: true,
      };
    case HIDE_WORKFLOW_FACET_POLYGON:
      return {
        ...state,
        workflowPanelDisplayFacetPolygon: false,
      };
    case REPLACE_FACET_METADATA: {
      const { facetAliasName, metadata } = payload;
      return {
        ...state,
        facetImages: state.facetImages.map((facetImage) => {
          if (facetImage.facetAliasName === facetAliasName) {
            return { ...facetImage, data: [metadata] };
          }
          return facetImage;
        }),
      };
    }
    case SET_WORKFLOW_BASEIMAGE_PARENT:
      return {
        ...state,
        workflowPanelSetBaseImgParent: payload,
      };
    case CLEAR_BASE_IMAGE_AND_FACETS:
      return {
        ...state,
        baseImage: null,
        facets: [],
        currentView: {},
      };
    case SET_WORKFLOW_VIEW_BASE_IMG: {
      const { anomalies, baseImage, selectedAnomalyId } = state;
      const selectedAnomaly = anomalies.find((x) => get(x, 'data[0].properties.anomalyId') === selectedAnomalyId);
      const baseImageAsset = baseImage ? util.getMapviewerAsset({
        assetType: 'iwimage',
        tileUrls: [`${ASSESS_IMAGES_API_ENDPOINT}/${baseImage.image_urn}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&${sharedUtil.authParam()}`],
        metadata: baseImage,
      }) : null;
      return {
        ...state,
        selectedAnomalyView:
          baseImageAsset && selectedAnomaly
            ? util.getViewFromAnomaly(
              [baseImageAsset],
              selectedAnomaly.data[0].geometries[0],
              WORKFLOW_PANEL.WIDTH,
              WORKFLOW_PANEL.HEIGHT,
            )
            : {},
      };
    }
    case FETCH_ACCESSORIES_MASTER_LIST:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_ACCESSORIES_MASTER_LIST_SUCCESS:
      return {
        ...state,
        accessoriesMasterList: payload.wall,
        loading: { ...state.loading, [FETCH_ACCESSORIES_MASTER_LIST]: false },
      };
    case FETCH_ACCESSORIES_MASTER_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_ACCESSORIES_MASTER_LIST]: false },
      };
    case FETCH_ACCESSORIES_SUMMARY:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_ACCESSORIES_SUMMARY_SUCCESS:
      return {
        ...state,
        accessoriesSummary: get(payload, 'accessories', {}),
        loading: { ...state.loading, [FETCH_ACCESSORIES_SUMMARY]: false },
      };
    case FETCH_ACCESSORIES_SUMMARY_FAILURE:
      return {
        ...state,
        accessoriesSummary: {},
        loading: { ...state.loading, [FETCH_ACCESSORIES_SUMMARY]: false },
      };
    // Multi Structure
    case SET_SELECTED_STRUCTURE_ID:
      return {
        ...state,
        selectedStructureId: payload,
        appliedGalleryImageFilters: util.clearGalleryImageFilters(state.appliedGalleryImageFilters),
      };
    case FETCH_MULTISTRUCTURE_DECISION_SUCCESS:
      return {
        ...state,
        roofDecision: payload.decisions,
        structureRoofMapping: payload.structureRoofMapping,
      };
    case UPDATE_STRUCTURE_VISIT_STATUS_SUCCESS: {
      const multiStructure = { ...state.multiStructure };
      const updatedStructure = multiStructure.structures.find((structure) => structure.structureID === payload.structureID);
      const metaData = get(updatedStructure, 'meta');
      if (!isEmpty(metaData)) {
        const visited = get(metaData, 'visited');
        if (!isEmpty(visited)) {
          updatedStructure.meta.visited[payload.userRole] = true;
        } else {
          updatedStructure.meta.visited = {
            [payload.userRole]: true,
          };
        }
      } else {
        updatedStructure.meta = {
          visited: {
            [payload.userRole]: true,
          },
        };
      }
      return {
        ...state,
        multiStructure,
      };
    }
    case REJECT_QC_STRUCTURE_SAGA_SUCCESS_ACTION:
      return {
        ...state,
        rejectedQcStructures: state.rejectedQcStructures.setIn(
          [payload.orderId, payload.structureId],
          { rejected: true, roofSkippedState: payload.roofSkippedState },
        ),
      };
    case REJECT_QC_STRUCTURE_SAGA_FAILURE_ACTION:
      return {
        ...state,
        errorToastMessage: 'qcPanel.rejectQcStructureFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case UNDO_QC_STRUCTURE_REJECTION_SAGA_SUCCESS_ACTION:
      return {
        ...state,
        rejectedQcStructures: state.rejectedQcStructures.setIn(
          [payload.orderId, payload.structureId],
          { rejected: false, roofSkippedState: payload.roofSkippedState },
        ),
      };
    case UNDO_QC_STRUCTURE_REJECTION_SAGA_FAILURE_ACTION:
      return {
        ...state,
        errorToastMessage: 'qcPanel.undoQcStructureRejectionFailed',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case FETCH_ANOMALY_IMAGES_SUCCESS_ACTION:
      return {
        ...state,
        anomalyImages: util.filterAnomalyImages(get(payload, 'data.images', [])),
        facetScanMetadata: get(payload, 'data.images[0]', {}),
        loading: { ...state.loading, [FETCH_ANOMALY_IMAGES]: false },
      };
    case FETCH_ANOMALY_IMAGES_FAILURE_ACTION:
      return {
        ...state,
        errorToastMessage: 'adjuster.unableToFetchFacetScanImages',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        loading: { ...state.loading, [FETCH_ANOMALY_IMAGES]: false },
      };
    // Fetch product cost
    case FETCH_PRODUCT_COST_SUCCESS:
      return {
        ...state,
        roofRopertCost: util.getRoofReportCostLabelFromResp(get(payload, 'Amount', 0), get(payload, 'Currency')),
        loading: { ...state.loading, [FETCH_PRODUCT_COST]: false },
      };
    case FETCH_PRODUCT_COST_FAILURE:
      return {
        ...state,
        errorToastMessage: 'adjuster.unableToFetchProductCost',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        loading: { ...state.loading, [FETCH_PRODUCT_COST]: false },
        roofRopertCost: '',
      };
    case CLEAR_REPORT_STATUS:
      return {
        ...state,
        roofMeasurementReportStatus: '',
      };
    /* Generate roof report */
    case GENERATE_ROOF_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [GENERATE_ROOF_REPORT]: false },
        roofMeasurementReportStatus: REPORT_STATUS_IN_PROCESS,
      };
    case GENERATE_ROOF_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [GENERATE_ROOF_REPORT]: false },
        error: { ...state.error, [GENERATE_ROOF_REPORT]: payload },
        roofMeasurementReportStatus: REPORT_STATUS_PAYMENT_FAILED,
      };
    /* Regenerate roof report */
    case REGENERATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [REGENERATE_REPORT]: false },
        assessStatus: REGENERATE_REPORT_STATUS_INPROCESS,
      };
    case REGENERATE_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [REGENERATE_REPORT]: false },
        error: { ...state.error, [REGENERATE_REPORT]: payload },
        assessStatus: REGENERATE_REPORT_STATUS_INPROCESS,
      };

    /* Save Qc note */
    case SAVE_QC_NOTE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_QC_NOTE]: false },
      };
    case SAVE_QC_NOTE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_QC_NOTE]: false },
        errorToastMessage: 'statusbar.noteSaveError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case CLEAR_ANNOTATION_TO_EDIT:
      return {
        ...state,
        createdAnnotationToEdit: {},
      };

    /* fetch soft metal images included in report */
    case FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_SUCCESS:
      return {
        ...state,
        reportIncludedSoftMetalImages: payload && payload.ReferenceID ? payload.ReferenceID : [],
        loading: { ...state.loading, [FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES]: false },
      };
    case FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES]: false },
        errorToastMessage: 'softMetal.fetchReportIncludedImages',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* save image report inclusion */
    case SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS:
      return {
        ...state,
        reportIncludedSoftMetalImages: payload
          && payload.ReferenceID && [...state.reportIncludedSoftMetalImages, ...payload.ReferenceID],
        loading: { ...state.loading, [SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION]: false },
      };
    case SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION]: false },
        errorToastMessage: 'softMetal.imageReportInclusionError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };

    /* delete image report inclusion */
    case DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS: {
      const { referenceIds } = payload;
      return {
        ...state,
        reportIncludedSoftMetalImages: [...state.reportIncludedSoftMetalImages].filter(
          (image) => !referenceIds.includes(image),
        ),
        loading: { ...state.loading, [DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION]: false },
      };
    }
    case DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION]: false },
        errorToastMessage: 'softMetal.imageReportRemovingError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_SUCCESS:
      return {
        ...state,
        reportIncludedSkydioImages: get(payload, 'ReferenceID', []),
        loading: {
          ...state.loading,
          [FETCH_REPORT_INCLUDED_SKYDIO_IMAGES]: false,
        },
      };
    case FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [FETCH_REPORT_INCLUDED_SKYDIO_IMAGES]: false,
        },
        errorToastMessage: 'skydio.fetchReportIncludedImages',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS:
      return {
        ...state,
        reportIncludedSkydioImages: [
          ...state.reportIncludedSkydioImages,
          ...get(payload, 'ReferenceID', []),
        ],
        loading: {
          ...state.loading,
          [SAVE_SKYDIO_IMAGE_REPORT_INCLUSION]: false,
        },
      };
    case SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [SAVE_SKYDIO_IMAGE_REPORT_INCLUSION]: false,
        },
        errorToastMessage: 'skydio.imageReportInclusionError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS: {
      const { referenceIds } = payload;
      return {
        ...state,
        reportIncludedSkydioImages: [...state.reportIncludedSkydioImages].filter((image) => !referenceIds.includes(image)),
        loading: {
          ...state.loading,
          [DELETE_SKYDIO_IMAGE_REPORT_INCLUSION]: false,
        },
      };
    }
    case DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [DELETE_SKYDIO_IMAGE_REPORT_INCLUSION]: false,
        },
        errorToastMessage: 'skydio.imageReportRemovingError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case GET_ANOMALY_NOTES_SUCCESS:
      return {
        ...state,
        anomalyNotes: get(payload, 'anomalies.0.notes', []),
        loading: {
          ...state.loading,
          [GET_ANOMALY_NOTES]: false,
        },
      };
    case GET_ANOMALY_NOTES_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [GET_ANOMALY_NOTES]: false,
        },
        errorToastMessage: get(payload, 'message') || 'adjuster.getAnomalyNotesError',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case ADD_ANOMALY_NOTE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [ADD_ANOMALY_NOTE]: false,
        },
      };
    case ADD_ANOMALY_NOTE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [ADD_ANOMALY_NOTE]: false,
        },
        errorToastMessage: get(payload, 'message') || 'adjuster.addAnomalyNoteError',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case UPDATE_ANOMALY_NOTE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [UPDATE_ANOMALY_NOTE]: false,
        },
      };
    case UPDATE_ANOMALY_NOTE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [UPDATE_ANOMALY_NOTE]: false,
        },
        errorToastMessage: get(payload, 'message') || 'adjuster.updateAnomalyNoteError',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case UPDATE_DATE_OF_LOSS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [UPDATE_DATE_OF_LOSS]: false,
        },
        errorToastMessage: 'adjuster.updateDateOfLossSuccessToast',
        showErrorToast: true,
        severity: 'success',
        assessStatus: REGENERATE_REPORT_STATUS_INPROCESS,
      };
    case UPDATE_DATE_OF_LOSS_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [UPDATE_DATE_OF_LOSS]: false,
        },
        errorToastMessage: get(payload, 'message') || 'adjuster.updateDateOfLossError',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case INCLUDE_ANOMALY_NOTE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [INCLUDE_ANOMALY_NOTE]: false,
        },
      };
    case INCLUDE_ANOMALY_NOTE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [INCLUDE_ANOMALY_NOTE]: false,
        },
        errorToastMessage: get(payload, 'message') || 'adjuster.includeAnomalyNoteError',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case SET_FACET_SCAN_IMAGE_ASSETS:
      return {
        ...state,
        facetScanImageAssets: payload,
      };
    case FETCH_FACET_IMAGE_ANOMALIES_SUCCESS: {
      let updatedAnomalies = [];
      if (!isEmpty(payload)) {
        const filteredAnomalies = util.getUpdatedAnomaliesRawImagery(payload, state.selectedConfidenceFactor, state.assessAppSettings);
        const anomalyIdsMap = filteredAnomalies.map((anomaly) => get(anomaly, 'data.0.properties.anomalyId'));
        updatedAnomalies = payload.filter((anomaly) => anomalyIdsMap.includes(anomaly.anomalyId));
      }
      return {
        ...state,
        rawImageAnomalies: updatedAnomalies,
        loading: {
          ...state.loading,
          [FETCH_FACET_IMAGE_ANOMALIES]: false,
        },
      };
    }
    case FETCH_FACET_IMAGE_ANOMALIES_FAILURE:
      return {
        ...state,
        rawImageAnomalies: [],
        loading: {
          ...state.loading,
          [FETCH_FACET_IMAGE_ANOMALIES]: false,
        },
        errorToastMessage: get(payload, 'message') || 'rawImagery.fetchImageAnomalyError',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case SET_RAW_IMAGE_SELECTED_ANOMALY_ID:
      return {
        ...state,
        rawImageSelectedAnomalyId: payload,
      };
    case FETCH_SHARED_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [FETCH_SHARED_USERS_LIST]: false,
        },
        sharedUsersList: (payload.shared_user_list || []).map((item) => item && item.shared_user),
      };
    case FETCH_SHARED_USERS_LIST_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [FETCH_SHARED_USERS_LIST]: false,
        },
        errorToastMessage: 'shareReport.fetchSharedUsersFailedToastMsg',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case CREATE_SHARE_LINK_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [CREATE_SHARE_LINK]: false,
        },
        sharedUsersList: [...new Set([...state.sharedUsersList, ...payload.userList.map((item) => item && item.sharedUserID)])],
        errorToastMessage: 'shareReport.shareSuccessToastMsg',
        showErrorToast: true,
        severity: 'success',
      };
    case CREATE_SHARE_LINK_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [CREATE_SHARE_LINK]: false,
        },
        errorToastMessage: 'shareReport.shareFailedToastMsg',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: true,
      };
    case DISABLE_GENERATE_REPORT:
      return {
        ...state,
        disableGenerateReport: true,
      };
    case REPORT_ATTEMPTS:
      return {
        ...state,
        sharedOrderId: get(payload, 'can_generate_report', false),
      };
    case REPORT_ATTEMPTS_SUCCESS: {
      const canGenerateReport = get(payload, 'can_generate_report', false);
      return {
        ...state,
        canGenerateReport,
        errorToastMessage: 'shareReport.reportAttempsExposedMsg',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: !canGenerateReport,
      };
    }
    case REPORT_ATTEMPTS_FAILURE:
      return {
        ...state,
        canGenerateReport: false,
        errorToastMessage: 'shareReport.reportAttempsExposedMsg',
        errorCode: get(payload, 'errorCode'),
        severity: 'error',
        showErrorToast: false,
      };
    case FETCH_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload,
      };
    case UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload,
        loading: { ...state.loading, [UPDATE_ACCESS_TOKEN]: true },
        errorToastMessage: 'adjuster.updateAccessTokenMsg',
        errorCode: '',
        severity: 'info',
        showErrorToast: true,
      };
    case UPDATE_ACCESS_TOKEN_COMPLETED:
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_ACCESS_TOKEN]: false },
        showErrorToast: false,
      };
    default:
      return state;
  }
};
export default adjusterReducer;
