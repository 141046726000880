import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  EVDatePicker,
  EVEditOutlined,
  EVIconButton,
  EVTooltip,
} from '@eagleview/ev-comp-library';
import { useDispatch } from 'react-redux';
import { updateDateOfLossAction } from '../../Adjuster.actions';
import useStyles from './EditDateOfLoss.styles';

const EditDateOfLoss = ({ orderID, policyDateOfLoss, disableEditDateOfLoss }) => {
  const [selectedDate, setSelectedDate] = useState(new Date(policyDateOfLoss));
  const [open, setOpen] = useState(false);
  const today = new Date();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleIconClick = () => {
    setOpen(!open);
  };

  const handleSubmit = (date) => {
    setOpen(false);

    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    dispatch(updateDateOfLossAction({ orderId: orderID, dateOfLoss: formattedDate }));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleSubmit(date);
  };

  return (
    <div className={classes.datePickerWrapper}>
      <EVTooltip
        title={disableEditDateOfLoss ? 'Roof report updation in progress, please try after roof report is updated' : 'Click to edit Date of Loss'}
      >
        <EVIconButton
          onClick={handleIconClick}
          disabled={disableEditDateOfLoss}
          className={classes.editButton}
        >
          <EVEditOutlined />
        </EVIconButton>
      </EVTooltip>

      <div>
        <EVDatePicker
          value={selectedDate}
          id="date-picker-inline"
          className={classes.datePicker}
          open={open}
          onChange={handleDateChange}
          onClose={handleCancel}
          TextFieldComponent={() => null}
          maxDate={today}
          format="dd MMM yyyy"
        />
      </div>
    </div>
  );
};

EditDateOfLoss.propTypes = {
  orderID: PropTypes.string.isRequired,
  policyDateOfLoss: PropTypes.string.isRequired,
  disableEditDateOfLoss: PropTypes.bool.isRequired,
};

export default EditDateOfLoss;
