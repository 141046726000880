import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  EVBox, EVTypography, useTheme, EVLink, EVNavigationIcon, EVNoteIcon, EVInfoOutlinedIcon, EVDialog, EVButton,
} from '@eagleview/ev-comp-library';
import { isEntitled, getUser } from 'utils/auth.utils';
import EntitledComponent from 'components/EntitledComponent';
import {
  FEATURE_ENTITLEMENTS,
  GENERATE_ROOF_REPORT_FLAG,
  ASSESS_MARKETING_PRODUCTNAMES,
  ASSESS_MEASUREMENT_FEE_PRODUCT,
  ASSESS_D2M_MULTISTRUCTURE,
  ASSESS_VIEW_MULTISTRUCTURE,
  ASSESS_DOL_UPDATE,
} from 'layout/entitleUser/EntitleUser.constants';
import {
  dateOfLossStatusMsgMap,
  ROOF_REPORT, MAP_FLIGHT_TYPE, orderStatuses, purchaseStatusTranslation,
  MAP_FLIGHT_TYPE_WITH_MARKETING_PRODUCT_NAME, LEGACY_STATUS_COMPLETED,
  LEGACY_STATUS_INPROCESS, REGENERATE_REPORT_STATUS_FAILED_RETRY,
  REGENERATE_REPORT_STATUS_INPROCESS,
} from 'layout/adjuster/Adjuster.constants';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import moment from 'moment/moment';
import PurchaseReportDialog from 'layout/adjuster/purchase-3d-report/PurchaseReportDialog';
import * as action from 'layout/adjuster/Adjuster.actions';
import useStyles from '../AdjusterSidePanel.styles';
import EditDateOfLoss from './EditDateOfLoss';

const SidePanelDetails = () => {
  // selectors
  const policyInsurancePerilCode = useSelector((state) => state.adjusterReducer.policyInsurancePerilCode);
  const policyDateOfLoss = useSelector((state) => state.adjusterReducer.policyDateOfLoss);
  const stormDate = useSelector((state) => state.adjusterReducer.stormDate);
  const windDirection = useSelector((state) => state.adjusterReducer.windDirection);
  const windGust = useSelector((state) => state.adjusterReducer.windGust);
  const hailSize = useSelector((state) => state.adjusterReducer.hailSize);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const EVRoofReports = useSelector((state) => state.adjusterReducer.EVRoofReports);
  const legacyStatus = useSelector((state) => state.adjusterReducer.legacyStatus);
  const orderID = useSelector((state) => state.adjusterReducer.orderId);
  const flightType = useSelector((state) => state.adjusterReducer.flightType);
  const dateOrderCapture = useSelector((state) => state.adjusterReducer.dateOrderCapture);
  const dateCreated = useSelector((state) => state.adjusterReducer.dateCreated);
  const dateCreatedUnixCode = useSelector((state) => state.adjusterReducer.dateCreatedUnixCode);
  const isRoofMeasurementOrder = useSelector((state) => state.adjusterReducer.IsRoofMeasurementEnabled);
  const roofMeasurementReportStatus = useSelector((state) => state.adjusterReducer.roofMeasurementReportStatus);
  const orderStatus = useSelector((state) => state.adjusterReducer.orderStatus);
  const enableRoofReportGeneration = useSelector((state) => get(state.entitleUserReducer.featureFlags, GENERATE_ROOF_REPORT_FLAG, false));
  const enableDateOfLossUpdate = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_DOL_UPDATE, false));
  const roofRopertCost = useSelector((state) => state.adjusterReducer.roofRopertCost);
  const enableMarketingProductNames = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_MARKETING_PRODUCTNAMES, false));
  const isOrderPlacedWithMeasurementFeeProduct = useSelector((state) => state.adjusterReducer.isOrderPlacedWithMeasurementFeeProduct);
  const isD2mOrder = useSelector((state) => state.adjusterReducer.isD2mOrder);
  const isAssessMeasurementFeeProductEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_MEASUREMENT_FEE_PRODUCT,
    false,
  ));
  const isMultiFamilyResidential = useSelector((state) => state.adjusterReducer.isMultiFamilyResidential);
  const isMultiStructure = useSelector((state) => state.adjusterReducer.multiStructure.isMultiStructure);
  const msAssessD2M = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_D2M_MULTISTRUCTURE, false));
  const msAssessView = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_VIEW_MULTISTRUCTURE, false));
  const assessStatus = useSelector((state) => state.adjusterReducer.assessStatus);
  const galleryImages = useSelector((state) => state.adjusterReducer.galleryImages);

  // actions
  const dispatch = useDispatch();
  const showReportUsingReportId = (payload) => dispatch(action.showReportUsingReportId(payload));
  const fetchProductCost = (payload) => dispatch(action.fetchProductCost(payload));
  const regenerateReportAction = (payload) => dispatch(action.regenerateReportAction(payload));
  const setAppliedFilters = (payload) => dispatch(action.setAppliedGalleryImageFilters(payload));

  // utility hooks
  const styles = useStyles({ windDirection });
  const { t } = useTranslation();
  const theme = useTheme();

  const [showPurchaseReportPanel, setPurchaseReportPanel] = useState(false);

  const flightTypeValue = (enableMarketingProductNames ? MAP_FLIGHT_TYPE_WITH_MARKETING_PRODUCT_NAME : MAP_FLIGHT_TYPE)[flightType];

  const showRoofReportBanner = isRoofMeasurementOrder
    || (!isRoofMeasurementOrder && flightType === 'fullScan' && isD2mOrder === 'true');

  const showPurchaseReportBtn = () => {
    if (isMultiFamilyResidential) {
      return false;
    }
    const isMeasurementFeeProductFFEnabled = isAssessMeasurementFeeProductEnabled ? isOrderPlacedWithMeasurementFeeProduct : true;
    return isMeasurementFeeProductFFEnabled && enableRoofReportGeneration
            && isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_SELF_ORGANIZATION_ORDERS)
            && isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS)
            && isEmpty(EVRoofReports) && [LEGACY_STATUS_COMPLETED, LEGACY_STATUS_INPROCESS].includes(legacyStatus);
  };

  const handleRoofReportLinkUsingReportID = (e, reportID) => {
    e.preventDefault();
    showReportUsingReportId({
      orderID,
      reportID,
      reportType: 'roof',
    });
  };

  useEffect(() => {
    if (showPurchaseReportBtn() && isEmpty(roofMeasurementReportStatus)) {
      fetchProductCost({ orderId: orderID, emailId: getUser().userName });
    }
  }, []);

  useEffect(() => {
    if (galleryImages && galleryImages.length && flightType === 'quickCapture') {
      setAppliedFilters({});
    }
  }, [galleryImages]);

  useEffect(() => {
    if (assessStatus === REGENERATE_REPORT_STATUS_FAILED_RETRY) {
      const regenerateRoofReportLogs = JSON.parse(localStorage.getItem('regenerateRoofReportLogs')) || {};

      if (!regenerateRoofReportLogs[orderID]) {
        regenerateRoofReportLogs[orderID] = { FailedAttempts: 0 };
        localStorage.setItem('regenerateRoofReportLogs', JSON.stringify(regenerateRoofReportLogs));
      }

      if (regenerateRoofReportLogs[orderID].FailedAttempts < 3) {
        regenerateRoofReportLogs[orderID].FailedAttempts += 1;
        localStorage.setItem('regenerateRoofReportLogs', JSON.stringify(regenerateRoofReportLogs));
        regenerateReportAction({ orderId: orderID });
      }
    }
  }, [assessStatus]);

  // eslint-disable-next-line consistent-return
  const getRoofReportList = () => {
    const createdOn = moment.unix(dateCreatedUnixCode);
    const ageInDays = moment().diff(createdOn, 'days');
    if (showPurchaseReportBtn() && (!isEmpty(roofMeasurementReportStatus)
       || (!isRoofMeasurementOrder && ageInDays < 60 && orderStatus === orderStatuses.READYFORREVIEW))
    ) {
      const labelKey = flightType === 'fullScan' ? 'adjuster.tab.orderRoofReport' : 'adjuster.tab.generateRoofReport';
      return (
        <EVBox id="roofMeasurementAfterCaptureBox" className={styles.roofReportBox}>
          {
            isEmpty(roofMeasurementReportStatus) ? (
              <>
                <EVNoteIcon className={styles.reportIcon} />
                <EVButton
                  disabled={isEmpty(roofRopertCost) || (flightType !== 'fullScan' && isMultiStructure && (!msAssessD2M || !msAssessView))}
                  className={styles.reportLink}
                  id="triggerPurchaseModalForPurchaseBtn"
                  onClick={() => setPurchaseReportPanel(true)}
                >
                  {t(labelKey)}
                </EVButton>
              </>
            ) : (
              <>
                <EVTypography variant="h4">
                  {t('adjuster.tab.3dRoofReport')}
                </EVTypography>
                <EVTypography variant="h4" className={styles.reportPurchaseStatus}>
                  {t(purchaseStatusTranslation[roofMeasurementReportStatus])}
                </EVTypography>
                <EVLink
                  className={styles.statusLink}
                  id="triggerPurchaseModalForStatusBtn"
                  onClick={() => setPurchaseReportPanel(true)}
                >
                  <EVInfoOutlinedIcon className={styles.infoIcon} />
                </EVLink>
              </>
            )
          }
        </EVBox>
      );
    }
    if (Array.isArray(EVRoofReports) && EVRoofReports.length) {
      return (
        EVRoofReports.map((report, index) => (
          report.isPresent && (
          <EVBox key={report.reportID} className={styles.roofReportBox}>
            <EVNoteIcon className={styles.reportIcon} />
            <EVLink
              className={styles.reportLink}
              onClick={(e) => handleRoofReportLinkUsingReportID(e, report.reportID)}
            >
              {`${ROOF_REPORT} ${EVRoofReports.length > 1 ? index + 1 : ''}`}

            </EVLink>
          </EVBox>
          )
        )));
    }
    return <EVTypography variant="body1">{t('adjuster.tab.notAvailable')}</EVTypography>;
  };

  return (
    <EVBox id="info" className={styles.detailsTab}>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.capture')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexWrap="wrap"
      >
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.flightType')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {t(flightTypeValue) || '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.dateCaptured')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {dateOrderCapture || '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%">
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.dateCreated')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%">
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {dateCreated || '-'}
          </EVTypography>
        </EVBox>
      </EVBox>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.policy')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexWrap="wrap"
      >
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.dateOfLoss')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <div className={styles.container}>
            <EVTypography variant="h4" className={styles.tabTableValues}>
              {policyDateOfLoss || '-'}
            </EVTypography>
            {enableDateOfLossUpdate && orderStatus === orderStatuses.READYFORREVIEW && (
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_REPORTS}>
                <EditDateOfLoss
                  orderID={orderID}
                  policyDateOfLoss={policyDateOfLoss}
                  disableEditDateOfLoss={
                    assessStatus === REGENERATE_REPORT_STATUS_INPROCESS || assessStatus === REGENERATE_REPORT_STATUS_FAILED_RETRY
                  }
                />
              </EntitledComponent>
            )}
          </div>
        </EVBox>
        {policyInsurancePerilCode && (
          <>
            <EVBox flex="1 1 45%">
              <EVTypography className={styles.tabTableText} variant="h4">
                {t('adjuster.tab.perilCode')}
              </EVTypography>
            </EVBox>
            <EVBox flex="1 1 55%">
              <EVTypography variant="h4" className={styles.tabTableValues}>
                {policyInsurancePerilCode}
              </EVTypography>
            </EVBox>
          </>
        )}
      </EVBox>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.weather')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexWrap="wrap"
      >
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.stormDate')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {stormDate || '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.windDirection')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            <EVBox display="flex" alignItems="center">
              {windDirection || '-'}
              {windDirection && <EVNavigationIcon className={styles.windDirectionIcon} />}
            </EVBox>
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.windGust')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%" marginBottom={theme.evSpacing['ev-spacing--16']}>
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {windGust ? `${windGust} mph` : '-'}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 45%">
          <EVTypography className={styles.tabTableText} variant="h4">
            {t('adjuster.tab.hailSize')}
          </EVTypography>
        </EVBox>
        <EVBox flex="1 1 55%">
          <EVTypography variant="h4" className={styles.tabTableValues}>
            {hailSize ? `${hailSize} in.` : '-'}
          </EVTypography>
        </EVBox>
      </EVBox>
      <EVTypography variant="h4" className={styles.detailsTabSectionText}>
        {t('adjuster.tab.eagleviewReports')}
      </EVTypography>
      <EVBox
        borderBottom={`1px solid ${theme.palette.grey[100]}`}
        padding={theme.evSpacing['ev-spacing--16']}
        marginBottom={theme.evSpacing['ev-spacing--16']}
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
      >
        {getRoofReportList}
      </EVBox>
      <EVDialog
        id="purchaseReportDialog"
        open={showPurchaseReportPanel}
        PaperProps={{ className: styles.purchaseReportDialogPaper }}
      >
        <PurchaseReportDialog
          id="purchaseReportDialogCmp"
          roofReportStatus={roofMeasurementReportStatus}
          closePanel={() => setPurchaseReportPanel(false)}
        />
      </EVDialog>
      {showRoofReportBanner && !isEmpty(dateOfLossStatusMsgMap[assessStatus]) && (
      <div id="regenerateReportMessage" className={styles.regenerateReportMessage}>
        {t(dateOfLossStatusMsgMap[assessStatus])}
      </div>
      )}
    </EVBox>
  );
};

export default SidePanelDetails;
