import React, { useEffect, useState } from 'react';
import {
  EVBox,
  EVToolbarPanel,
  useTheme,
  EVIconButton,
  EVBadge,
  EVMultiStructureIcon,
  EVCompass,
  EVTypography,
} from '@eagleview/ev-comp-library';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import ToastMessage from 'components/toast-message';
import downloadIcon from 'assets/download.svg';
import unsupportedImg from 'assets/unsupported.svg';
import {
  ASSESS_PHOTO_VIEWER_MS, ASSESS_DOWNLOAD_RAW_IMAGERY,
  FEATURE_ENTITLEMENTS,
} from 'layout/entitleUser/EntitleUser.constants';
import { claimStatus } from 'layout/homeLayout/home/Home.constants';
import { isEntitled } from 'utils/auth.utils';
import useStyles from './PhotosUI.styles';
import PhotosUITabs, { PhotosUITab } from './PhotosUITabs';
import Statusbar from './PhotosUIStatusBar';
import RightAlignedSidePanel from './RightAlignedSidePanel';
import * as action from './Photos.actions';
import PhotosSidePanel from './photos-sidepanel/PhotosSidePanel';
import PhotosMapViewer from './photos-mapviewer';
import PhotosToolbar from './photos-toolbar';
import PhotosFacetOverviewModal from './photos-facet-overview-modal';
import PhotosMissingSummaryJsonView from './photos-missingSummaryJsonView';
import PhotosMultiStructureMenu from './photos-multistructure-menu';
import { PROCESSING_READY_TO_CAPTURE_PAYMENT_STATUS } from './photos.constant';

const PhotosUI = () => {
  const theme = useTheme();
  // local state
  const [zoom, setZoom] = useState(0);
  const [zoomChanged, setZoomChanged] = useState(null);
  const [selectedFacetOverviewImage, setSelectedFacetOverviewImage] = useState('');
  const [hoveredFacetOverviewImage, setHoveredFacetOverviewImage] = useState('');
  const [hoveredFacetOverviewImageFromGrid, setHoveredFacetOverviewImageFromGrid] = useState('');
  const [multiStructureMenuOpen, setMultiStructureMenuOpen] = useState(false);
  const [downloadInprogress, setDownloadInprogress] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [exploreOverviewImageRotation, setExploreOverviewImageRotation] = useState(0);

  // selectors
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  const exploreOverviewMetadata = useSelector((state) => get(state, 'photosReducer.exploreOverviewMeta', {}));
  const facetOverviewMetadata = useSelector((state) => get(state, 'photosReducer.facetOverviewMeta', {}));
  const exploreOverviewImage = useSelector((state) => get(state, 'photosReducer.fullImages.overviewImage', {}));
  const cameraPositionsArray = useSelector((state) => get(state, 'photosReducer.cameraPositions.facet_overviews', []));
  const facetOverviewsCount = useSelector((state) => get(state, 'photosReducer.facetOverviewsCount', 0));
  const totalFacetScans = useSelector((state) => get(state, 'photosReducer.totalFacetScans', 0));
  const isSummaryJsonAvailable = useSelector((state) => get(state, 'photosReducer.isSummaryJsonAvailable', false));
  const isUnsupported = useSelector((state) => get(state, 'photosReducer.isPhotosUnspported', false));

  const toastMessage = useSelector((state) => get(state, 'photosReducer.errorToastMessage'));
  const errorCode = useSelector((state) => get(state, 'photosReducer.errorCode', ''));
  const showToast = useSelector((state) => get(state, 'photosReducer.showErrorToast'));
  const toastSeverity = useSelector((state) => get(state, 'photosReducer.severity'));
  const toastAutoHideDuration = useSelector((state) => state.photosReducer.toastAutoHideDuration);
  const isPhotoViewerMultiStructureEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_PHOTO_VIEWER_MS, false));
  const isAssessZipDownloadEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_DOWNLOAD_RAW_IMAGERY, false));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));
  const { isMultiStructure = false, structures: structuresList = [] } = useSelector((state) => get(state, 'photosReducer.multiStructure', {}));
  const zipDownloadStatusId = useSelector((state) => get(state, 'photosReducer.zipDownloadStatusId', ''));
  const zipDownloadTriggerSuccess = useSelector((state) => get(state, 'photosReducer.zipDownloadTriggerSuccess', false));
  const orderDetails = useSelector((state) => get(state, 'photosReducer.orderDetails', {}));
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);

  const isQCrole = (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_MANUAL_QC_INFO)
  || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS));

  const disabledMosaic = !(
    [claimStatus.READYFORREVIEW, claimStatus.COMPLETED, claimStatus.ORDERCLOSED].includes(orderDetails.Status)
    || orderDetails.AssessStatus === PROCESSING_READY_TO_CAPTURE_PAYMENT_STATUS
    || (get(orderDetails, 'Info.Details.manualQCEnabled') === 'true' && isQCrole)
  );

  // Hooks
  const styles = useStyles({ sidePanelWidth });
  const { id: orderId } = useParams();
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const fetchOrderDetails = (payload) => dispatch(action.fetchOrderDetailsAction(payload));
  const fetchPhotoAssets = (payload) => dispatch(action.fetchPhotoAssetsAction(payload));
  const fetchExploreOverview = (payload) => dispatch(action.fetchFullImageAction(payload));
  const handleToastClose = () => dispatch(action.closeErrorToast());
  const initiateZipDownloadAction = (payload) => dispatch(action.initiateZipDownloadAction(payload));
  const checkZipStatusAction = (payload) => dispatch(action.checkZipStatusAction(payload));
  const clearZipStatusIdAction = () => dispatch(action.clearZipStatusIdAction());

  const statusBarData = [{
    label: '',
    value: isEmpty(selectedFacetOverviewImage)
      ? get(exploreOverviewMetadata, 'name', '')
      : get(facetOverviewMetadata, 'name', ''),
  }, {
    label: 'photos-ui.totalRoofOverviews',
    value: facetOverviewsCount > 0 ? facetOverviewsCount : '--',
  }, {
    label: 'photos-ui.totalScans',
    value: totalFacetScans > 0 ? totalFacetScans : '--',
  }];

  useEffect(() => {
    clearZipStatusIdAction();
    fetchOrderDetails({ orderId });
    fetchPhotoAssets({
      orderId,
      structureId: selectedStructureId,
      requestType: 'facet_overviews',
    });
  }, [orderId, selectedStructureId]);

  useEffect(() => {
    if (!isEmpty(exploreOverviewMetadata) && !isEmpty(exploreOverviewMetadata.name)) {
      const rotationAngle = get(exploreOverviewMetadata, 'rotation_angle', 0);
      // update the angle of -180 to 180 scale to 0 to 360 scale and rotate the image accordingly
      const angle = 360 - (rotationAngle < 0 ? rotationAngle + 360 : rotationAngle);
      setExploreOverviewImageRotation(angle);
      setRotation(angle);
      const imageName = get(exploreOverviewMetadata, 'name');
      fetchExploreOverview({
        orderId,
        imageName,
        structureId: selectedStructureId,
        imageType: 'exploreOverview',
      });
    }
  }, [exploreOverviewMetadata]);

  useEffect(() => {
    if (zipDownloadStatusId !== '' && zipDownloadTriggerSuccess) {
      setDownloadInprogress(true);
      checkZipStatusAction({
        uuid: zipDownloadStatusId,
      });
    } else {
      setDownloadInprogress(false);
    }
  }, [zipDownloadStatusId]);

  const handleZoomInButton = () => {
    const newZoom = zoom + 1;
    setZoomChanged(newZoom);
  };

  const handleZoomOutButton = () => {
    const newZoom = zoom - 1;
    setZoomChanged(newZoom);
  };

  const setZoomLevel = (z) => {
    setZoom(z);
  };

  const handleAnnotationSelection = (annotationObj) => {
    const eventType = get(annotationObj, 'eventType', '');
    const imageName = get(annotationObj, 'selectedImageName', '');
    if (eventType === 'click') {
      setSelectedFacetOverviewImage(imageName);
    }
    if (eventType === 'hover') {
      setHoveredFacetOverviewImage(imageName);
    }
  };
  const onThumbnailItemClicked = (image) => {
    setSelectedFacetOverviewImage(image.imageName);
  };

  const onThumbnailItemHover = (image) => {
    if (!isEmpty(image)) {
      setHoveredFacetOverviewImageFromGrid(image.imageName);
    } else {
      setHoveredFacetOverviewImageFromGrid('');
    }
  };

  const handleMultiStructureMenu = (event) => {
    setMultiStructureMenuOpen(event.currentTarget);
  };

  const initiateZipDownload = () => {
    initiateZipDownloadAction(
      {
        orderId,
        structureId: selectedStructureId,
      },
    );
  };

  const imageCorners = get(exploreOverviewMetadata, 'corners', {});

  const debounceSetRotation = debounce(setRotation, 10);

  return (
    <EVBox>
      <PhotosSidePanel showTabs={{ details: true, roof: false, gallery: true }} />
      {(isUnsupported || !isSummaryJsonAvailable)
        && (
          <EVBox>
            <EVBox className={styles.buttonsContainer}>
              <PhotosUITabs
                tab={PhotosUITab.GALLERY}
                disableMosaic={disabledMosaic}
              />
            </EVBox>
            {(isPhotoViewerMultiStructureEnabled || isAssessZipDownloadEnabled) && (
            <EVBox>
              <EVBox className={styles.orderToolbar}>
                <EVToolbarPanel
                  displayType="vertical"
                  iconStyles={{ height: 'auto' }}
                >
                  {isAssessZipDownloadEnabled && (
                  <EVIconButton
                    id="downloadImageryButton"
                    className={styles.toolbarIconButton}
                    onClick={initiateZipDownload}
                    tooltip={downloadInprogress ? t('download-imagery-in-progress') : t('download-imagery-start')}
                    disabled={downloadInprogress}
                  >
                    <img alt="" src={downloadIcon} />
                  </EVIconButton>
                  )}
                  {isPhotoViewerMultiStructureEnabled && isMultiStructure && (
                    <EVBadge badgeContent={0} color="error">
                      <EVIconButton
                        id="multiStructureMenu"
                        className={styles.toolbarIconButton}
                        onClick={handleMultiStructureMenu}
                        tooltip={t('photos-ui.selectStructure')}
                      >
                        <EVMultiStructureIcon color={multiStructureMenuOpen ? 'primary' : 'inherit'} />
                      </EVIconButton>
                    </EVBadge>
                  )}
                </EVToolbarPanel>
              </EVBox>
              <PhotosMultiStructureMenu
                menuOpenEl={multiStructureMenuOpen}
                structures={structuresList}
                setMenuOpen={setMultiStructureMenuOpen}
              />
            </EVBox>
            )}
            {isUnsupported ? (
              <EVBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                height="100vh"
              >
                <EVBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={unsupportedImg} alt="unsupported" />
                </EVBox>
                <EVBox mb={4} width="80%">
                  <EVTypography variant="body1" textAlign="center">
                    {t('photos-ui.unsupported')}
                  </EVTypography>
                </EVBox>
              </EVBox>
            ) : (
              <PhotosMissingSummaryJsonView
                orderId={orderId}
              />
            )}
          </EVBox>
        )}
      {isSummaryJsonAvailable && !isUnsupported && (
      <EVBox>
        { !isEmpty(exploreOverviewImage) && !isEmpty(selectedFacetOverviewImage) && (
        <PhotosFacetOverviewModal
          orderId={orderId}
          facetOverviewImageName={selectedFacetOverviewImage}
          clearSelectedFacetOverviewImage={() => setSelectedFacetOverviewImage('')}
        />
        )}
        <RightAlignedSidePanel
          orderId={orderId}
          onThumbnailItemClicked={onThumbnailItemClicked}
          onThumbnailItemHover={onThumbnailItemHover}
          hoveredGridItem={hoveredFacetOverviewImage}
        />

        <EVBox className={styles.wrapper}>
          <EVBox className={styles.mapContainer}>
            <EVBox className={styles.buttonsContainer}>
              <PhotosUITabs
                tab={PhotosUITab.GALLERY}
                disableMosaic={disabledMosaic}
              />
            </EVBox>
            {isPhotoViewerMultiStructureEnabled && isMultiStructure && (
            <>
              <EVBox className={styles.orderToolbar}>
                <EVToolbarPanel
                  displayType="vertical"
                  iconStyles={{ height: 'auto', borderRadius: '4px' }}
                  backgroundColor={theme.palette.common.white}
                  style={{ borderRadius: '4px' }}
                  border="1px solid #ccc"
                >
                  <EVBadge badgeContent={0} color="error">
                    <EVIconButton
                      id="multiStructureMenu"
                      onClick={handleMultiStructureMenu}
                      tooltip={t('photos-ui.selectStructure')}
                    >
                      <EVMultiStructureIcon color={multiStructureMenuOpen ? 'primary' : 'inherit'} />
                    </EVIconButton>
                  </EVBadge>
                </EVToolbarPanel>
              </EVBox>
              <PhotosMultiStructureMenu
                menuOpenEl={multiStructureMenuOpen}
                structures={structuresList}
                setMenuOpen={setMultiStructureMenuOpen}
              />
            </>
            )}
            <PhotosToolbar
              handleZoomInButton={handleZoomInButton}
              handleZoomOutButton={handleZoomOutButton}
            />
            <EVBox className={styles.main}>
              {!isEmpty(exploreOverviewImage) && (
              <PhotosMapViewer
                imageCorners={imageCorners}
                image={exploreOverviewImage}
                cameraPositions={cameraPositionsArray}
                setZoomLevel={setZoomLevel}
                zoomChanged={zoomChanged}
                setSelectedAnnotation={handleAnnotationSelection}
                cameraIconHoveredFromGrid={hoveredFacetOverviewImageFromGrid}
                rotation={rotation}
              />
              )}
              <EVBox className={styles.compassStyle}>
                <EVCompass
                  updatedAngle={exploreOverviewImageRotation}
                  onAngleUpdate={debounceSetRotation}
                  compassWidth="70"
                  knobSize={20}
                  knobRadius={10}
                  showNorthIndicator
                />
              </EVBox>
            </EVBox>
          </EVBox>
        </EVBox>
      </EVBox>
      )}
      <Statusbar
        data={
          isEmpty(selectedFacetOverviewImage)
            ? statusBarData
            : statusBarData.filter((e) => e.label !== 'photos-ui.totalRoofOverviews')
        }
      />
      <ToastMessage
        alertMsg={`${t(toastMessage)} ${(toastSeverity === 'error' && errorCode) ? `${t('adjuster.referCode')} ${errorCode}` : ''
        }`}
        severity={toastSeverity}
        open={showToast}
        onClose={handleToastClose}
        autoHideDuration={toastAutoHideDuration}
      />
    </EVBox>

  );
};

export default PhotosUI;
